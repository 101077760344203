import React from "react";

// reactstrap components
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Container,Row, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SocialSiteButtons from "./SocialSiteButtons.js";
// core components

function ProductPageHeader() {
  let pageHeader = React.createRef();
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        //pageHeader.current.style.transform =
        //  "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  const history = useHistory();

  const routeChangeTikTok = () =>{
    let path = "https://www.tiktok.com/@grantblaise";
    window.open(path, '_blank');
  }

  const routeChange = () =>{
    let path = "https://www.facebook.com/grantblaiseoreillyart";
    history.push(path);
  }

  const routeChangeFaceBook = () =>{
    let path = "https://www.facebook.com/grantblaiseoreillyart";
    window.open(path, '_blank');
  }

  const routeChangeInstagram = () =>{
    let path = "https://www.instagram.com/grantblaiseart/";
    window.open(path, '_blank');
  }

  const routeChangeSaatchi = () =>{
    let path = "https://www.saatchiart.com/account/profile/2213487";
    window.open(path, '_blank');
  }

  return (
    <>
      <div className="page-header page-header-mini">
        <div
          className="page-header-image"
          style={{
            backgroundColor: 'rgba(0,0,0,0.6)',
            backgroundBlendMode: 'multiply',
            backgroundImage:
              "url(" + require("assets/img/art-gallery-2.jpg").default + ")",
          }}
          ref={pageHeader}
        ></div>
        <div className="content-center">
          <Container>
            <Col>
            <h1 className="title">Virtual Gallery</h1>
            <h5 className="description" style={{fontWeight:'bold'}}>
              Here is your opportunity to own an original painting from the Grant Blaise O'Reilly collection.
            </h5>

            <Row>
               <SocialSiteButtons />
              </Row>
           </Col>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ProductPageHeader;

// <Button
//   className="btn-icon btn-round mr-1"
//   color="info"
//   onClick={routeChangeSaatchi}
// >
// <img
//   alt="..."
//   style={{width:32}}
//   src={require("assets/img/saatchiArt.png").default}
// ></img>
// </Button>
