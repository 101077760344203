import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

// core components

function GBOAShowRoomHeader() {
  let pageHeader = React.createRef();

  return (
    <>
    <div className="page-header page-header-mini">
      <div className="rellax-header" data-rellax-speed="-4">
        <div
          className="page-header-image"
          style={{
            backgroundColor: 'rgba(0,0,0,0.6)',
            backgroundBlendMode: 'multiply',
            backgroundImage:
              "url(" +
              require("assets/img/art-gallery.jpg")
                .default +
              ")",
          }}
        ></div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h1 className="title">Show Room</h1>
              <h4 className="category">Display of the latest art work created by Grant Blaise O'Reilly</h4>
              <h5>
                Show room gives an idea how the art work presents in different settings.
              </h5>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    </>
  );
}

export default GBOAShowRoomHeader;
