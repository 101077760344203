import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { Link,  useHistory} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// core components

function GBOAFollowUs() {

  const history = useHistory();

  const routeChangeFaceBook = () =>{
    let path = "https://www.facebook.com/grantblaiseoreillyart";
    window.open(path, '_blank');
    //history.push(path);
  }

  const routeChangeInstagram = () =>{
    let path = "https://www.instagram.com/grantblaiseart/";
    window.open(path, '_blank');
    //history.push(path);
  }

  const routeChangeTikTok = () =>{
    let path = "https://www.tiktok.com/@grantblaise";
    window.open(path, '_blank');
  }

  return (
    <>
      <div
        className="section features-7 section-image"
        style={{height:'550px',
          backgroundImage:
            "url(" + require("assets/img/presentation-page/follow-me-facebook-button-keyboard.png").default + ")",
        }}
      >
        <Container fluid>
          <Row>
            <Col className="ml-auto mr-auto" lg="5" md="7" style={{marginTop:5}}>
              <h2 className="title">Follow Me on Facebook and Instagram</h2>
              <h6 className="category">All my artwork, recent releases and sneak peeks are on Facebook and Instagram.</h6>
              <hr></hr>

                <Button
                  className="btn-round btn-icon mr-1"
                  color="facebook"
                  size="lg"
                  onClick={routeChangeFaceBook}
                >
                  <FontAwesomeIcon icon="fab fa-facebook-square" style={{fontSize:30}}/>
                </Button>
                <Button
                  className="btn-icon btn-round mr-1"
                  style={{marginLeft:10}}
                  color="instagram"
                  size="lg"
                  onClick={routeChangeInstagram}
                >
                    <img
                      alt="..."
                      className="img img-raised rounded"
                      src={require("assets/img/instagram.png").default}
                    ></img>
                </Button>
                <Button
                  className="btn-icon btn-round mr-1"
                  style={{marginLeft:10}}
                  color="instagram"
                  size="lg"
                  onClick={routeChangeTikTok}
                >
                    <img
                      alt="..."
                      className="img img-raised rounded"
                      src={require("assets/img/tiktok.png").default}
                    ></img>
                </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default GBOAFollowUs;
