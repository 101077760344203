/*
================================================================================
Grant Blaise O'Reilly's Art Component
Ausphere product 2023-03-02
Developed by Grant O'Reilly
================================================================================
*/

import React, { useReducer} from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
// core components
import GBOAScrollTransparentNavbar from "components/Navbars/GBOAScrollTransparentNavbar.js";
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import HomePresentationHeader from "components/Headers/HomePresentationHeader.js";

// sections for this page
import StudioBasicComponents from "./presentation-sections/StudioBasicComponents.js";
import BasicComponents from "./presentation-sections/BasicComponents.js";
import Cards from "./presentation-sections/Cards.js";
import GBOAProfile from "./presentation-sections/GBOAProfile.js";
import GBOALatestRelease from "./presentation-sections/GBOALatestRelease.js";
import GBOAUpComing from "./presentation-sections/GBOAUpComing.js";
import GBOAFollowUs from "./presentation-sections/GBOAFollowUs.js";
import GBOAVideoReels from "./presentation-sections/GBOAVideoReels.js";

function Home() {

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("presentation-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">

        {/*<GBOAScrollTransparentNavbar />*/}
        <HomePresentationHeader />
        <GBOALatestRelease />
        <GBOAUpComing />
        <GBOAVideoReels />
        <BasicComponents />
        <Cards />
        <StudioBasicComponents viewButton={'true'} />
        <GBOAFollowUs/>}
        <GBOAProfile />
      </div>
    </>
  );
}

export default Home;
library.add(fab, fas, far)
