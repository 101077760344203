import React,{useContext} from 'react'
import "./CartItem.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { REMOVE_CART_ITEM } from "../../Services/Cart/action-types";
//import { CartContext } from "../../Services/Cart/CartContext";
import {
  Button
} from "reactstrap";

const CartItem = ({cartObj}) => {

  // const { cartItem, dispatch } = useContext(CartContext);
  //
  // const removeFromCart = (item) => {
  //   item.isAddedtoCart = false;
  //   item.count = 0;
  //   dispatch({
  //     type: REMOVE_CART_ITEM,
  //     payload: item,
  //   });
  //   console.log(cartItem);
  // };

    return (
        <div style={{borderRadius:20, background: 'lightgray', padding:'10px',marginBottom:10}}>
            <div style={{display:'flex', flexDirection: 'row', justifyContent:'space-between'}}>
               <img src={require("assets/art/"+cartObj.images[1].filename).default} className="w-36 h-30 ml-0" />
               <div style={{marginLeft:65, marginTop:-10}}>
                 {/*<Button className="btn-icon btn-round"
                         color="info"
                         onClick={()=> removeFromCart(cartObj)}>
                        <FontAwesomeIcon icon="fa-regular fa-trash-can" />
                 </Button> */}
               </div>
            </div>
            <div style={{display:'flex', flexDirection: 'column', justifyContent:'flex-start'}}>
                <span style={{fontWeight:'bold', fontSize: 24}}>{cartObj.name}</span>
                <div style={{display:'flex', flexDirection: 'row', justifyContent:'flex-start'}}>
                   <span style={{fontWeight:'bold', fontSize: 18}}>Price:</span >
                   <span style={{fontWeight:'bold', fontSize: 18, marginLeft:5, color: 'darkorange'}}>{cartObj.price}</span >
                </div>
            </div>
        </div>
    )
}

export default CartItem
