/*!

=========================================================
* Now UI Kit PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-pro-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React , { useReducer, useState, useEffect} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/react-demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages
import GBOAPortfolio from "views/examples/GBOAPortfolio.js";
import GBOANews from "views/examples/GBOANews.js";
import GBOAShowRoom from "views/examples/GBOAShowRoom.js";
import GBOAContactUs from "views/examples/GBOAContactUs.js";
import GBOAAboutUs from "views/examples/GBOAAboutUs.js";
import GBOAPaintingContactUs from "views/examples/GBOAPaintingContactUs.js";
import GBOAProductPage from "views/examples/GBOAProductPageCarousel.js";
import GBOAPrintingPage from "views/examples/GBOAPrintingPage.js";
import GBOAPrintingContactUs from "views/examples/GBOAPrintingContactUs.js";
import Completion from "./components/ShoppingCart/Components/Stripe/Completion.js";
import Home from "views/Home.js";
// others
import { ProductsProvider } from './components/ShoppingCart/Components/GBOAProducts/GBOAProductsContext.js';
import {CartContext } from "./components/ShoppingCart/Services/Cart/CartContext";
import CartReducer from "./components/ShoppingCart/Services/Cart/reducer";
//import ProductReducer from "./components/ShoppingCart/Services/Product/reducer";
//import {ProductContext} from "./components/ShoppingCart/Services/Product/ProductContext";

function App(){
  //const [products, productDispatch] = useReducer(ProductReducer,[]);
  //const [products, setProducts] = useReducer(ProductReducer,[]);
  const [cartItem, dispatch] = useReducer(CartReducer,[]);
  const [totalCartItems, setTotalCartItems] = useState(0);

  const calculateCartItems = ()=>{
    let totalCart = 0;
    cartItem.map(item=>{
        totalCart = totalCart + item.count;
    })
    setTotalCartItems(totalCart);
  }

  useEffect(()=>{
    calculateCartItems();
},[cartItem]);

  return(
    <ProductsProvider>
     <CartContext.Provider value={{cartItem, dispatch}}>
        <BrowserRouter>
          <Switch>
            <Route path="/printing" render={(props) => <GBOAPrintingPage {...props} />} />
            <Route path="/portfolio" render={(props) => <GBOAPortfolio {...props} />} />
            <Route path="/news" render={(props) => <GBOANews {...props} />} />
            <Route path="/showroom" render={(props) => <GBOAShowRoom {...props} />} />
            <Route path="/contact-us" render={(props) => <GBOAContactUs {...props} />} />
            <Route path="/about-us" render={(props) => <GBOAAboutUs {...props} />} />
            <Route path="/painting-contact-us" render={(props) => <GBOAPaintingContactUs {...props} />} />
            <Route path="/printing-contact-us" render={(props) => <GBOAPrintingContactUs {...props} />} />
            <Route
              path="/gboa-product-page"
              render={(props) => <GBOAProductPage {...props} />}
            />
            <Route
              path="/home"
              render={(props) => <Home {...props} />}
            />
            <Route path="/completion" render={(props) => <Completion {...props} />} />
            <Redirect to="/home" />
          </Switch>

        </BrowserRouter>
    </CartContext.Provider>
  </ProductsProvider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
