import React, { useContext, useEffect, useState } from "react";
import CompletionHeader from "../../../Headers/CompletionHeader.js";
import ScrollTransparentNavbar from "../../../Navbars/ScrollTransparentNavbar.js";
import "../Cart/CartList.css";
import { useProductsDispatch } from '../GBOAProducts/GBOAProductsContext.js';
import { CartContext } from "../../Services/Cart/CartContext";
import CartItem from "../Cart/CartItem";
import { REMOVE_CART_ITEM } from "../../Services/Cart/action-types";
import Rellax from "rellax";

function Completion(props) {

  const { cartItem, dispatch } = useContext(CartContext);
  const prodDispatch = useProductsDispatch();

  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("presentation-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });

  const updateItems = () =>{
    cartItem.map((item) => {
      prodDispatch({
        type: 'changed',
        products: {
          ...item,
          sold: true
        }
      })
    });

    prodDispatch({type: 'save'});
  }

  const removeFromCart = () => {
    cartItem.map((item) => (
      dispatch({
        type: REMOVE_CART_ITEM,
        payload: item,
               })
    ));
  };

  return(

    <div>
    <ScrollTransparentNavbar />
    <CompletionHeader />
    {updateItems()}
    {removeFromCart()}
    </div>
  );
}


// {/*<h1>Thank you! 🎉</h1>*/}

export default Completion;
