import React from "react";
import emailjs from '@emailjs/browser';
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter,CustomInput
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import GBOAScrollTransparentHeader from "components/Navbars/GBOAScrollTransparentHeader.js";
import ContactUsHeader from "components/Headers/ContactUsHeader.js";
import GBOAFooterSocial from "components/Footers/GBOAFooterSocial.js";


function GBOAPaintingContactUs() {

  const location = useLocation();
  let  data  = location.state;
  const [artWorkFocus, setArtWorkFocus] = React.useState(false);
  const [priceFocus, setPriceFocus] = React.useState(false);
  const [first1Focus, setFirst1Focus] = React.useState(false);
  const [last1Focus, setLast1Focus] = React.useState(false);
  const [email1Focus, setEmail1Focus] = React.useState(false);
  const [first2Focus, setFirst2Focus] = React.useState(false);
  const [email2Focus, setEmail2Focus] = React.useState(false);

  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [numberFocus, setNumberFocus] = React.useState(false);

  const [modal, setModal] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState('Message Box');
  const [modalTitle, setModalTitle] = React.useState('Some message.');

  const toggle = () => { setModal(!modal); window.scrollTo(0, 0);
  document.body.scrollTop = 0;}

  React.useEffect(() => {
    document.body.classList.add("painting-contact-page");
    document.body.classList.add("painting-sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("painting-contact-page");
      document.body.classList.remove("painting-sidebar-collapse");
    };
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    if (e.target[2].value === ""){
      setModalTitle("First name error!");
      setModalMessage("A first name has not being entered.");
      toggle();
    }
    else if (e.target[3].value === ""){
      setModalTitle("Last name error!");
      setModalMessage("A last name has not being entered.");
      toggle();
    }
    else if (e.target[4].value === ""){
      setModalTitle("No email address error!");
      setModalMessage("An email has not being entered.");
      toggle();
    }
    else if (e.target[5].value === ""){
      setModalTitle("No message error!");
      setModalMessage("No message has not being entered.");
      toggle();
    }
    else if (e.target[6].checked === false){
      setModalTitle("Could be a robot error!");
      setModalMessage("Robot check has NOT been checked.");
      toggle();
    }
    else{
      e.target[0].value = data.name;
      e.target[1].value = data.price;
      emailjs.sendForm('service_91nn8yk', 'template_igx5bp9', e.target, '1Sw8UQpm6tZCxspv3')
        .then((result) => {
          setModalTitle("Message Sent");
          setModalMessage("We will get back to you shortly.");
          toggle();
        }, (error) => {
            setModalTitle("An error occurred");
            setModalMessage("Please try again" + error.text);
            toggle();
        });
    }

  };

  if (data === undefined){
    data = {"image": "Ukukhanya.jpg",
            "name": "",
            "price": ""
           };
  }

  return (
    <>
      <div
        className="contactus-1 section-image"
        style={{
          backgroundImage:
            "url(" + require("assets/img/art-gallery-3.jpg").default + ")"
        }}
      >
        <ScrollTransparentNavbar />
        {/*GBOAScrollTransparentHeader(false)*/}
        <Container>
          <Row>
            <Col md="5">
            <h3 className="title">{data.name} {data.price}</h3>

            <h4 className="description" >
              Need more information on the {data.name} art work? Complete the "Contact Us" form and we'll immediately contact you back.
            </h4>

            <div className="card-image">
              <img
                alt="..."
                style={{borderRadius: 20}}
                src={
                  require("assets/art/"+data.image)
                    .default
                }
              ></img>
            </div>



            </Col>
            <Col className="ml-auto mr-auto" md="5">
              <Card className="card-contact card-raised">
                <Form id="contact-form1" method="post" role="form" onSubmit={sendEmail}>
                  <CardHeader className="text-center">
                    <CardTitle tag="h4">Request Information Form</CardTitle>
                  </CardHeader>
                  <CardBody>
                  <Row>
                  <Col className="pr-2" md="6">
                    <label>Art work</label>
                    <InputGroup
                      className={artWorkFocus ? "input-group-focus" : ""}
                    >
                      <Input
                        aria-label="Art Work..."
                        autoComplete="given-name"
                        placeholder={data.name}
                        name = "art_work"
                        type="text"
                        vaLUE = {data.name}
                        onFocus={() => setArtWorkFocus(true)}
                        onBlur={() => setArtWorkFocus(false)}
                        disable
                      ></Input>
                    </InputGroup>
                  </Col>
                  <Col className="pr-2" md="6">
                    <label>Price</label>
                    <InputGroup
                      className={priceFocus ? "input-group-focus" : ""}
                    >
                      <Input
                        aria-label="Price..."
                        autoComplete="given-name"
                        placeholder={data.price}
                        name = "price"
                        type="text"
                        onFocus={() => setPriceFocus(true)}
                        onBlur={() => setPriceFocus(false)}
                        disable
                      ></Input>
                    </InputGroup>
                  </Col>
                  </Row>
                    <Row>
                      <Col className="pr-2" md="6">
                        <label>First name</label>
                        <InputGroup
                          className={first1Focus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <FontAwesomeIcon icon="far fa-user"/>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            aria-label="First Name..."
                            autoComplete="given-name"
                            placeholder="First Name..."
                            name = "first_name"
                            type="text"
                            onFocus={() => setFirst1Focus(true)}
                            onBlur={() => setFirst1Focus(false)}
                          ></Input>
                        </InputGroup>
                      </Col>
                      <Col className="pl-2" md="6">
                        <FormGroup>
                          <label>Last name</label>
                          <InputGroup
                            className={last1Focus ? "input-group-focus" : ""}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <FontAwesomeIcon icon="far fa-circle-user" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              aria-label="Last Name..."
                              autoComplete="family-name"
                              placeholder="Last Name..."
                              name = "last_name"
                              type="text"
                              onFocus={() => setLast1Focus(true)}
                              onBlur={() => setLast1Focus(false)}
                            ></Input>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <label>Email address</label>
                      <InputGroup
                        className={email1Focus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <FontAwesomeIcon icon="fa-regular fa-envelope" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          autoComplete="email"
                          placeholder="Email Here..."
                          type="email"
                          name = "email"
                          onFocus={() => setEmail1Focus(true)}
                          onBlur={() => setEmail1Focus(false)}
                        ></Input>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <label>Your message</label>
                      <Input
                        id="message"
                        name="message"
                        rows="6"
                        type="textarea"
                      ></Input>
                    </FormGroup>
                    <Row>
                      <Col md="6">

                        <FormGroup>
                            <CustomInput type="checkbox" id="exampleCustomCheckbox" label="I'm not a robot" />
                        </FormGroup>

                      </Col>
                      <Col md="6">
                        <Button
                          className="btn-round pull-right"
                          color="info"
                          type="submit"
                        >
                          Send Message
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Form>
              </Card>
            </Col>
          </Row>
          <Row>
          <Col md="4">
          <div className="info info-horizontal">
            <div className="description">
              <h4 className="info-title"><FontAwesomeIcon icon="far fa-pen-to-square" style={{marginRight:10}}/>Request Information Form</h4>
              <p className="description">
                - Introduction to the artist. <br></br>
                - Begin communication and discuss the purchasing of the art piece. <br></br>
                - More information and photos of the art. <br></br>
              </p>
            </div>
          </div>
          </Col>
          <Col md="4">
          <div className="info info-horizontal">
            <div className="description">
              <h4 className="info-title">
                  <FontAwesomeIcon icon="far fa-image" style={{marginRight:10}}/>Framing</h4>
              <p className="description">
                - The art piece is NOT framed.  <br></br>
                - Arrangements to frame can be discussed (Additional costs apply).  <br></br>
              </p>
            </div>
          </div>
          </Col>
          <Col md="4">
          <div className="info info-horizontal">
            <div className="description">
              <h4 className="info-title">
                  <FontAwesomeIcon icon="far fa-paper-plane" style={{marginRight:10}}/>Shipping</h4>
              <p className="description">
                - Shipping costs outside Australia are not covered. <br></br>
                - International shipping costs apply.
                - Tracking numbers will be mailed to customer. <br></br>
              </p>
            </div>
          </div>
          </Col>
          </Row>
        </Container>
      </div>
      <div>
                <GBOAFooterSocial />
      </div>

      <Modal style={{position:'fixed', top: '10%', left: '45%'}} isOpen={modal} toggle={toggle}>
          <ModalHeader
              toggle={toggle}>{modalTitle}</ModalHeader>
          <ModalBody>
              {modalMessage}
          </ModalBody>
          <ModalFooter>
              <Button color="primary" onClick={toggle}>Ok</Button>
          </ModalFooter>
      </Modal>

    </>
  );
}

export default GBOAPaintingContactUs;
/*
<FormGroup check>
  <Label check>
    <Input type="radio"></Input>
    <span className="form-check-sign"></span>
    I'm not a robot
  </Label>
</FormGroup>
*/
