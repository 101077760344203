/*eslint-disable*/
import React from "react";
import { useHistory } from "react-router-dom";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components

function GBOAProfile() {

  const history = useHistory();

  const routeChangeTikTok = () =>{
    let path = "https://www.tiktok.com/@grantblaise";
    window.open(path, '_blank');
  }

  const routeChange = () =>{
    let path = "https://www.facebook.com/grantblaiseoreillyart";
    window.open(path, '_blank');
  }

  const routeChangeInstagram = () =>{
    let path = "https://www.instagram.com/grantblaiseart/";
    window.open(path, '_blank');
  }

  const routeChangeGBO = () =>{
    let path = "https://www.facebook.com/grantblaise.oreilly";
    window.open(path, '_blank');
  }

  const routeChangeLinkedin = () =>{
    let path = "https://www.linkedin.com/in/grantboreilly/";
    window.open(path, '_blank');
  }

  const routeChangeGoogle = () =>{
    let path = "https://scholar.google.com/citations?user=3K0mLSIAAAAJ&hl=en&oi=ao";
    window.open(path, '_blank');
  }

  return (
    <>
      <div className="section section-pricing" id="sectionBuy" data-background-color="dark-blue">
      <Container>
      <Col className="mr-1" md="12">
        <Card className="card-profile card-plain">
          <div className="card-avatar">
            <a href="#pablo" onClick={(e) => e.preventDefault()}>
              <img
                alt="..."
                className="img img-raised"
                src={require("assets/logo/GBO-01.jpg").default}
              ></img>
            </a>
          </div>
          <CardBody>
            <CardTitle tag="h3">Grant Blaise O'Reilly</CardTitle>
            <h6 className="category text-info">Artist and Scientist</h6>
            <p className="card-description">
              A love for realism painting and sketching.
            </p>
            <CardFooter>
              <Button
                className="btn-icon btn-round"
                color="info"
                onClick={routeChangeLinkedin}
              >
                  <FontAwesomeIcon icon="fab fa-linkedin"/>
              </Button>
              <Button
                className="btn-icon btn-round"
                color="info"
                onClick={routeChangeGBO}
              >
                <FontAwesomeIcon icon="fab fa-facebook-square"/>
              </Button>
              <Button
                className="btn-icon btn-round"
                color="info"
                onClick={routeChangeGoogle}
              >
                <FontAwesomeIcon icon="fab fa-google"/>
              </Button>
            </CardFooter>
          </CardBody>
        </Card>
      </Col>
      </Container>
        <Container>
          <Row>
            <Col className="text-center ml-auto mr-auto mt-5" md="8">
              <h3>Thank you for supporting us!</h3>
            </Col>
            <Col className="text-center ml-auto mr-auto" md="8">
              <Button
                className="btn-round btn-icon mr-1"
                color="facebook"
                size="lg"
                onClick={routeChange}
              >
                <FontAwesomeIcon icon="fab fa-facebook-square" style={{fontSize:30}}/>
              </Button>
              <Button
                className="btn-icon btn-round mr-1"
                style={{marginLeft:10}}
                color="instagram"
                size="lg"
                onClick={routeChangeInstagram}
              >
                <FontAwesomeIcon icon="fa-brands fa-instagram" style={{fontSize:30}}/>
              </Button>
              <Button
                className="btn-icon btn-round mr-1"
                style={{marginLeft:10}}
                color="instagram"
                size="lg"
                onClick={routeChangeTikTok}
              >
                <FontAwesomeIcon icon="fa-brands fa-tiktok" style={{fontSize:30}}/>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default GBOAProfile;
