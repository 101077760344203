/*
================================================================================
Grant Blaise O'Reilly's Art Component
Ausphere product 2023-03-02
Developed by Grant O'Reilly
================================================================================
*/
import React, {useState, useEffect, useContext} from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

import GBOAPrintingDescItem from "./GBOAPrintingDescItem.js"

// const showButtons = (itemsFor, next, previous) =>{
//   if (itemsFor.length <= 1){
//     return(<></>)
//   }
//   else{
//     return(
//       <>
//       <a
//         className="carousel-control-prev"
//         data-slide="prev"
//         href="#pablo"
//         onClick={(e) => {
//           e.preventDefault();
//           previous();
//         }}
//         role="button"
//       >
//         <Button
//           className="btn-icon btn-round"
//           color="info"
//           name="button"
//           size="sm"
//           type="button"
//         >
//           <FontAwesomeIcon icon="fa-solid fa-circle-arrow-left" />
//         </Button>
//       </a>
//       <a
//         className="carousel-control-next"
//         data-slide="next"
//         href="#pablo"
//         onClick={(e) => {
//           e.preventDefault();
//           next();
//         }}
//         role="button"
//       >
//         <Button
//           className="btn-icon btn-round"
//           color="info"
//           name="button"
//           size="sm"
//           type="button"
//         >
//           <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
//         </Button>
//       </a>
//       </>
//     );
//   }
// }

// function GBOAPainting(name,
//                       artist,
//                       artistComment,
//                       paintingType,
//                       image,
//                       price,
//                       buttons,
//                       sold,
//                       description,
//                       artistInfo,
//                       itemsFor,
//                       marginTop,
//                       product){
//extractImages(product_item.images),

function GBOAPrintingProduct(product){

  // collapse states and functions

  //const [collapses, setCollapses] = React.useState([1]);
  // const [activeIndex, setActiveIndex] = React.useState(0);
  // const [animating, setAnimating] = React.useState(false);





  // const changeCollapse = (collapse) => {
  //   if (collapses.includes(collapse)) {
  //     setCollapses(collapses.filter((prop) => prop !== collapse));
  //   } else {
  //     setCollapses([...collapses, collapse]);
  //   }
  // };

  // const onExiting = () => {
  //   setAnimating(false);
  // };
  // const onExited = () => {
  //   setAnimating(false);
  // };
  // const next = () => {
  //   if (animating) return;
  //   const nextIndex = activeIndex === itemsFor.length - 1 ? 0 : activeIndex + 1;
  //   setActiveIndex(nextIndex);
  // };
  // const previous = () => {
  //   if (animating) return;
  //   const nextIndex = activeIndex === 0 ? itemsFor.length - 1 : activeIndex - 1;
  //   setActiveIndex(nextIndex);
  // };
  // const goToIndex = (newIndex) => {
  //   if (animating) return;
  //   setActiveIndex(newIndex);
  // };

  // const history = useHistory();
  //
  // const routeChange = () =>{
  //   //let path = "https://www.facebook.com/grantblaiseoreillyart/shop_tab";
  //   let path = "https://www.facebook.com/grantblaiseoreillyart";
  //   window.open(path, '_blank');
  // }
  //
  // const routeChangeSaatchi = () =>{
  //   let path = "https://www.saatchiart.com/account/profile/2213487";
  //   window.open(path, '_blank');
  // }
  // const extractImages = (images) => {
  //     let imageList = [];
  //     let count = 0;
  //     images.map((image) =>{
  //        if (count !== 1) //This is the ThumbNail displayImage
  //         imageList.push({ src: require("assets/art/"+image.filename).default, altText: "", caption: ""})
  //        count++;
  //     }
  //   )
  //   return imageList;
  // }


  return (

  <Container >
      <div className="section-story-overview">
        <Row>
           <Col md="5" style={{marginTop:product.marginTop}}>
           <img
             style={{borderRadius: 20}}
             src={require("assets/art/"+product.images[0].filename).default}
             alt={''}
             className="d-block img-raised"
           />
           </Col>
          <Col md="6">
            <h3 className="title">{product.name}</h3>

            {product.prints.map(print_item => (
              GBOAPrintingDescItem(print_item, product.images)
            ))}

            <Button
                    className="mr-3"
                    color="info"
                    to={{pathname: "/printing-contact-us",
                         state: { image : product.images[0].filename,
                                  price : "Print request",
                                  name  : product.name}
                       }}
                    tag={Link}>
              Print Requests / Info  
              <FontAwesomeIcon icon="fa-regular fa-envelope" />
            </Button>
          </Col>
        </Row>
      </div>
  </Container>
);
}

export default GBOAPrintingProduct;

/*

            <br></br>
            <div style={{border: '1px',borderStyle: 'solid',  borderColor:'#2CA8FF', color: '#2CA8FF',padding: '10px', fontSize: '1.1em' }}>
            <h5><b>{product.medium}</b></h5>
            <hr></hr>
            <Row>
              <Col className="ml-auto mr-auto" md="4">
                {product.fullsize}
              </Col>
              <Col className="ml-auto mr-auto" md="4">
                  {priceRequest(product.price)}
              </Col>
              <Col className="ml-auto mr-auto" md="4">
                  {cartButton(product.price,product.priceValue)}
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col className="ml-auto mr-auto" md="4">
                {product.halfsize}
              </Col>
              <Col className="ml-auto mr-auto" md="4">
                  {priceRequest(product.halfprice)}
              </Col>
              <Col className="ml-auto mr-auto" md="4">
                  {cartButton(product.halfprice,product.halfpriceValue)}
              </Col>
            </Row>
            </div>


            const shopButtons = () =>{
              if (product.sold === false){
                if (product.shop === "facebookshop" ){
                  return(
                     <Row className="justify-content-end">
                     <Button
                          style={{padding:'5px', width:120}}
                          color="info"
                          onClick={routeChange}
                        >
                        <img
                          alt="..."
                          src={require("assets/img/facebookshop.png").default}
                        ></img>
                      </Button>
                       <Button
                               className="mr-3"
                               color="info"
                               to={{pathname: "/painting-contact-us",
                                    state: { image : product.image,
                                             price : product.price,
                                             name  : product.name}
                                  }}
                               tag={Link}>
                         Enquire  
                         <FontAwesomeIcon icon="fa-regular fa-envelope" />
                       </Button>
                     </Row>
                  )
                }
                else if (product.shop === "gboashop" ) {

                  let val = isInCart(product);

                  if (val){
                      return(
                         <Row className="justify-content-end">
                          <Button
                                  style={{padding:'10px', width:180}}
                                  color="primary"
                                  to={{pathname: "/painting-contact-us",
                                       state: { image : product.images[1].filename,
                                                price : product.price,
                                                name  : product.name}
                                     }}
                                  onClick={() => removeFromCart({"id": product.id, "name":product.name, "sold":product.sold, "price":product.price, "priceValue":product.priceValue, "smallImage": require("assets/art/"+product.images[1].filename).default })}>
                                  Remove from Cart
                                  <FontAwesomeIcon icon="fa-solid fa-trash-can" style={{marginLeft:10, fontSize: '20'}}/>
                          </Button>
                         </Row>
                      )
                  }
                  else{

                          if (addCart){
                            return(
                               <Row className="justify-content-end">
                                <Button
                                        style={{padding:'10px', width:180}}
                                        color="primary"
                                        to={{pathname: "/painting-contact-us",
                                             state: { image : product.images[1].filename,
                                                      price : product.price,
                                                      name  : product.name}
                                           }}
                                        onClick={() => removeFromCart({"id": product.id, "name":product.name, "sold":product.sold, "price":product.price, "priceValue":product.priceValue, "smallImage": require("assets/art/"+product.images[1].filename).default })}>
                                        Remove from Cart
                                        <FontAwesomeIcon icon="fa-solid fa-trash-can" style={{marginLeft:10, fontSize: '20'}}/>
                                </Button>
                               </Row>
                            )
                          }
                          else{
                            return(
                               <Row className="justify-content-end">
                                <Button
                                        style={{padding:'10px', width:125}}
                                        color="success"
                                        to={{pathname: "/painting-contact-us",
                                             state: { image : require("assets/art/"+product.images[1].filename).default ,
                                                      price : product.price,
                                                      name  : product.name}
                                           }}
                                        //onClick={() => addToCart({"id": product.id, "name":product.name, "sold":product.sold, "price":product.price, "priceValue":product.priceValue, "smallImage": require("assets/art/"+product.images[1].filename).default })}>
                                        onClick={() => addToCart(product)}>
                                        Add to Cart
                                        <FontAwesomeIcon icon="fa-solid fa-cart-plus" style={{marginLeft:10, fontSize: '20'}}/>
                                </Button>
                                 <Button
                                         style={{padding:'10px', width:120}}
                                         color="info"
                                         to={{pathname: "/painting-contact-us",
                                              state: { image : product.image,
                                                       price : product.price,
                                                       name  : product.name}
                                            }}
                                         tag={Link}>
                                   Enquire  
                                   <FontAwesomeIcon icon="fa-regular fa-envelope" style={{marginLeft:10, fontSize: '20'}}/>
                                 </Button>
                               </Row>
                            )
                          }
                  }
                }
                else
                return(
                  <Row className="justify-content-end">
                    <Button
                            className="mr-3"
                            color="info"
                            to={{pathname: "/painting-contact-us",
                                 state: { image : product.images[0].filename,
                                          price : product.price,
                                          name  : product.name}
                               }}
                            tag={Link}>
                      Enquire  
                      <FontAwesomeIcon icon="fa-regular fa-envelope" />
                    </Button>
                  </Row>
                );
              }
              else{
                return(
                  <Row className="justify-content-end">
                       <img
                         style={{width:'120px', marginTop:-30}}
                         alt="..."
                         src={require("assets/img/sold.png").default}
                       ></img>
                  </Row>
                );
              }

            }

*/
