import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { Link,  useHistory} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPlayer from 'react-player';
import gahanaVideo1 from '../../assets/video/Gahana.mp4'
import gahanaVideo2 from '../../assets/video/Gahana-2.mp4'
import ukukhanya from '../../assets/video/Ukukhanya-reel.mp4'
// core components

function GBOAVideoReels() {

  const history = useHistory();

  const routeChangeFaceBook = () =>{
    let path = "https://www.facebook.com/grantblaiseoreillyart";
    window.open(path, '_blank');
    //history.push(path);
  }

  const routeChangeInstagram = () =>{
    let path = "https://www.instagram.com/grantblaiseart/";
    window.open(path, '_blank');
    //history.push(path);
  }

  const reel = (name) =>{
    return(
      <Col md="4" style={{align:'center'}}>
        <ReactPlayer
          url={name}
          width='100%'
          height='100%'
          controls = {true}
        />
      </Col>
    );
  }
  return (
    <>
    <div className="section features-7 section-image" style={{
      backgroundImage:
        "url(" + require("assets/img/bg45.jpg").default + ")",
    }} >
      <Container>
        <Row>
          <Col className="text-center ml-auto mr-auto" md="8">
            <div className="section-description">
              <h2 className="title">Reels</h2>
              <h6 className="category">Recent video reels of art work by Grant Blaise O'Reilly.</h6>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-center ml-auto mr-auto" md="8">
          <Button
            className="btn-round btn-icon mr-1"
            color="facebook"
            size="lg"
            onClick={routeChangeFaceBook}
          >
            <FontAwesomeIcon icon="fab fa-facebook-square" style={{fontSize:30}}/>
          </Button>
          <Button
            className="btn-icon btn-round mr-1"
            style={{marginLeft:10}}
            color="instagram"
            size="lg"
            onClick={routeChangeInstagram}
          >
              <img
                alt="..."
                className="img img-raised rounded"
                src={require("assets/img/instagram.png").default}
              ></img>
          </Button>
          </Col>
        </Row>
        <Row style={{marginTop:80}}>
          {reel(gahanaVideo1)}
          {reel(gahanaVideo2)}
          {reel(ukukhanya)}
        </Row>
      </Container>
    </div>
    </>
  );
}

export default GBOAVideoReels;
