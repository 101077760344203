import { createContext, useState, useEffect, useContext, useReducer } from 'react';

import Axios from "axios";

import FileSaver from "file-saver";

const ProductsContext = createContext(null);

const ProductsDispatchContext = createContext(null);

const saveProducts = (data) =>{
  const productdata = JSON.stringify(data);
  const response = Axios.post("https://grantblaiseart.ts.r.appspot.com/write", productdata , {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }
  });
  console.log(response);
}

export function ProductsProvider({ children }) {

  const fetchProducts = async() =>{

    const response = await Axios.get("https://grantblaiseart.ts.r.appspot.com/read", {
      method: 'GET',
      mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      }
    });
    const data = await response.data;

    dispatch({type:'fetch',
              products: data
            });
  }

  useEffect(() => {
      fetchProducts()
  }, []);


  const [products, dispatch] = useReducer(
    productsReducer,
    []
  );

  return (
    <ProductsContext.Provider value={products}>
      <ProductsDispatchContext.Provider value={dispatch}>
        {children}
      </ProductsDispatchContext.Provider>
    </ProductsContext.Provider>
  );
}

export function useProducts() {
  return useContext(ProductsContext);
}

export function useProductsDispatch() {
  return useContext(ProductsDispatchContext);
}

function productsReducer(products, action) {
  switch (action.type) {
    case 'save' :
         saveProducts(products);
         return products;
    case 'fetch':
         return action.products
    case 'added': {
      return [...products, {
        id: action.id,
        name: action.name,
        done: false
      }];
    }
    case 'changed': {
      return products.map(p => {
        if (p.id === action.products.id) {
          return action.products;
        } else {
          return p;
        }
      });
    }
    case 'deleted': {
      return products.filter(p => p.id !== action.id);
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
