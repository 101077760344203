import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
// core components

function Cards() {
  return (
    <>
      <div className="section section-cards" >
        <Container>
          <Row>
            <Col className="text-center ml-auto mr-auto" md="8">
              <div className="section-description">
                <h2 className="title">Portfolio</h2>
                <h6 className="category">Art work created by Grant Blaise O'Reilly</h6>
                <h5 className="description">
                  A display of the art work created by Grant Blaise O'Reilly over the last 30 years.
                </h5>
              </div>
              <Button
                className="btn-round"
                to="/portfolio"
                color="info"
                tag={Link}
              >
                Portfolio
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="images-container">
                <div className="image-container1 mr-1">
                  <img
                    alt="..."
                    src={
                      require("assets/art/LeopardInTree.jpg").default
                    }
                  ></img>
                </div>
                <div className="image-container2 mr-1">
                  <img
                    alt="..."
                    src={
                      require("assets/art/mother.jpg").default
                    }
                  ></img>
                </div>
                <div className="image-container3 mr-1">
                  <img
                    alt="..."
                    src={
                      require("assets/art/GreatSpirit.jpg").default
                    }
                  ></img>
                </div>
                <div className="image-container4 mr-1">
                  <img
                    alt="..."
                    src={
                      require("assets/art/TheHunt.jpg").default
                    }
                  ></img>
                </div>
                <div className="image-container5">
                  <img
                    alt="..."
                    src={
                      require("assets/art/Baobab.jpg").default
                    }
                  ></img>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Cards;
