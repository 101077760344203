import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
// core components

function GBOALatestRelease() {

  const paintingDetails = (imagefilename, name, description, size ) => {
    return(
      <Row style={{marginTop:80}}>
        <Col xl="8">
          <div>
            <img
              style={{borderRadius: 30, maxWidth: size}}
              alt="..."
              src={imagefilename}
            ></img>
          </div>
        </Col>
        <Col  xl="4">
            <h3 className="title">{name}</h3>
            <h5 className="description">
              {description}
            </h5>
            <Row>
              <Button
                className="btn-round"
                to="/gboa-product-page"
                color="info"
                tag={Link}
              >
                Virtual Gallery
              </Button>
              <Button
                className="btn-round"
                to="/printing"
                color="info"
                tag={Link}
              >
                Prints of paintings
              </Button>
            </Row>
          </Col>
      </Row>
    );
  }

  return (
    <>
    <div className="section features-7 section-image" style={{
      backgroundImage:
        "url(" + require("assets/img/bg11.jpg").default + ")",
    }} >
      <Container>
        <Row>
          <Col className="text-center ml-auto mr-auto" md="8">
            <div className="section-description">
              <h2 className="title">Latest Releases</h2>
              <h6 className="category">Recently completed art work by Grant Blaise O'Reilly.</h6>
            </div>
          </Col>
        </Row>
        {paintingDetails(require("assets/art/stealth.jpg").default,
                         "Stealth, the White Tiger",
                         "Stealth, the White Tiger is an original acrylic painting by Grant Blaise O'Reilly. The acrylic painting is a 101cm x 81cm canvas. Please visit the Virtual Gallery allows for pricing and contacting the artist.",
                         "100%"
                        )}

        {paintingDetails(require("assets/art/Thunder.jpg").default,
                         "Thunder, the African Elephant",
                         "Thunder, the African Elephant is an original acrylic painting by Grant Blaise O'Reilly. The acrylic painting is a 120cm x 100cm canvas. Please visit the Virtual Gallery allows for pricing and contacting the artist.",
                         "100%"
                        )}

        {paintingDetails(require("assets/art/BlackJack.jpg").default,
                         "Black Jack, the African Lion",
                         "Black Jack, the African Lion is an original acrylic painting by Grant Blaise O'Reilly. The acrylic painting is a 94.1cm x 94.1cm canvas. The name Black Jack was given to this lion as he had passed by a Black Jack bush and his mane was full of black jacks. Black Jack is for sale. Please visit the Virtual Gallery allows for pricing and contacting the artist.",
                         "100%"
                        )}

        {paintingDetails(require("assets/art/Mau.jpg").default,
                         "Mau, the tigeress",
                         "Mau, the tigeress is an original acrylic painting by Grant Blaise O'Reilly. The acrylic painting is a 101cm x 81cm canvas. The name Iklwa is taken from the deadly Zulu warrior spear developed by Shaka Zulu. Iklwa is for sale. Please visit the Virtual Gallery allows for pricing and contacting the artist.",
                         "100%"
                        )}

        {paintingDetails(require("assets/art/iklwa.jpg").default,
                         "Iklwa, the African Lion",
                         "Iklwa, Iklwa is an original acrylic painting by Grant Blaise O'Reilly. The acrylic painting is a 101cm x 81cm canvas. The name Iklwa is taken from the deadly Zulu warrior spear developed by Shaka Zulu. Iklwa is for sale. Please visit the Virtual Gallery allows for pricing and contacting the artist.",
                         "100%"
                        )}

        {paintingDetails(require("assets/art/Gahana.jpg").default,
                         "Gahana, Jewel of India",
                         "Gahana, Jewel of India is an original acrylic painting by Grant Blaise O'Reilly. The acrylic painting is a 90cmx60cm canvas. Gahana is the Hindu meaning for jewel. Gahana, Jewel of India is for sale. Please visit the Virtual Gallery allows for pricing and contacting the artist.",
                         "100%"
                        )}

        {paintingDetails(require("assets/art/Ukukhanya.jpg").default,
                         "Ukukhanya",
                         "Ukukhanya is an original oil painting by Grant Blaise O'Reilly. The oil painting is a 94.1cm x 94.1cm canvas. Ukukhanya is the Zulu name of the lion. It capture the essense of the piece. It's meaning is Zulu for 'glare'. Ukukhanya is for sale. Please visit the Virtual Gallery allows for pricing and contacting the artist.",
                         "100%"
                        )}

        {paintingDetails(require("assets/art/ScarTheLeopard.jpg").default,
                         "Scar the Leopard",
                         "Scar the Leopard is an original oil painting by Grant Blaise O'Reilly. The oil painting is 76cm x 60cm canvas. Scar the Leopard is for sale. Please visit the Virtual Gallery allows for pricing and contacting the artist.",
                         "70%"
                        )}

        {paintingDetails(require("assets/art/DueDrop.jpg").default,
                         "Dew Drop the Tiger",
                         "Dew Drop the Tiger is an original oil painting by Grant Blaise 'O Reilly. The oil painting is 50cm x 60cm canvas. Due Drop the Tiger is for sale. Please visit the Virtual Gallery allows for pricing and contacting the artist.",
                         "70%"
                        )}


      </Container>
    </div>
    </>
  );
}

export default GBOALatestRelease;
