import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

// core components

function GBOAPortfolioHeader() {
  let pageHeader = React.createRef();

  return (
    <>
    <div className="page-header page-header-mini">
        <div
          className="page-header-image"
          style={{
            backgroundColor: 'rgba(0,0,0,0)',
            backgroundBlendMode: 'multiply',
            backgroundImage:
              "url(" + require("assets/img/bg22.jpg").default + ")",
          }}
        ></div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h1 className="title">Portfolio</h1>
              <h4 className="category">Art work created by Grant Blaise O'Reilly</h4>
              <h5 className="description">
                A display of the art work created by Grant Blaise O'Reilly over the last 30 years.
                The long road travelled and still to be travelled to achieve perfection in my art work.
              </h5>
            </Col>
          </Row>
        </div>
    </div>
    </>
  );
}

export default GBOAPortfolioHeader;
/*
<div className="page-header page-header-mini">
  <div
    className="page-header-image"
    style={{
      backgroundImage:
        "url(" + require("assets/img/bg22.jpg").default + ")",
    }}
    ref={pageHeader}
  ></div>
  <div className="content-center">
    <Row>
      <Col className="ml-auto mr-auto" md="8">
        <h1 className="title">Portfolio</h1>
        <h4 className="category">Art work created by Grant Blasie O'Reilly</h4>
        <h5 className="description">
          A display of the art work created by Grant Blaise O'Reilly over the last 30 years.
          The long road travelled and still to be travelled to achieve perfection in my art work.
        </h5>
      </Col>
    </Row>
  </div>
</div>
*/
