import React from "react";
// react plugin used to create DropdownMenu for selecting items
import { Link } from "react-router-dom";
import Select from "react-select";
import Rellax from "rellax";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import GBOAScrollTransparentHeader from "components/Navbars/GBOAScrollTransparentHeader.js";
import GBOAPortfolioHeader from "components/Headers/GBOAPortfolioHeader.js";
import GBOAFooterSocial from "components/Footers/GBOAFooterSocial.js";
import GBOAImageComponent from "./GBOAImageComponent.js";

function GBOAPortfolio() {
  const [specialitySelect, setSpecialitySelect] = React.useState(null);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
    <ScrollTransparentNavbar />
    {/*GBOAScrollTransparentHeader(true)*/}
    <GBOAPortfolioHeader/>
      <div style ={{backgroundColor: '#031425'}} className="section section-sections">
        <Container fluid>
          <div className="section-cols">
          <Row className="text-center">
            <Col className="ml-auto mr-auto" md="6">
              <GBOAImageComponent image ={require("assets/art/stealth.jpg").default}
                                  imageSize = "85%"
                                  left  = "0%"
                                  top   = "0%"
                                  width = "200%"
              />
            </Col>
            <Col className="ml-auto mr-auto" md="6">
              <GBOAImageComponent image ={require("assets/art/Thunder.jpg").default}
                                  imageSize = "85%"
                                  left  = "-120%"
                                  top   = "0%"
                                  width = "200%"
              />
            </Col>
          </Row>
          <Row className="text-center" style={{marginTop:"2%"}}>
          <Col className="ml-auto mr-auto" md="4">
            <GBOAImageComponent image ={require("assets/art/BlackJack.jpg").default}
                                imageSize = "85%"
                                left  = "0%"
                                top   = "0%"
                                width = "200%"
            />
          </Col>
            <Col className="ml-auto mr-auto" md="4">
              <GBOAImageComponent image ={require("assets/art/Mau.jpg").default}
                                  imageSize = "85%"
                                  left  = "0%"
                                  top   = "0%"
                                  width = "200%"
              />
            </Col>
            <Col className="ml-auto mr-auto" md="4">
              <GBOAImageComponent image ={require("assets/art/iklwa.jpg").default}
                                  imageSize = "85%"
                                  left  = "0%"
                                  top   = "0%"
                                  width = "200%"
              />
            </Col>
          </Row>
          <Row className="text-center" style={{marginTop:"2%"}}>
          <Col className="ml-auto mr-auto" md="4">
            <GBOAImageComponent image ={require("assets/art/Gahana.jpg").default}
                                imageSize = "85%"
                                left  = "0%"
                                top   = "10%"
                                width = "200%"
            />
          </Col>

            <Col className="ml-auto mr-auto" md="4">
              <GBOAImageComponent image ={require("assets/art/Ukukhanya.jpg").default}
                                  imageSize = "85%"
                                  left  = "0%"
                                  top   = "10%"
                                  width = "200%"
              />
            </Col>
            <Col className="ml-auto mr-auto" md="4">
            <GBOAImageComponent image ={require("assets/art/AlphaPair.jpg").default}
                                imageSize = "85%"
                                left  = "-120%"
                                top   = "10%"
                                width = "200%"
            />
            </Col>
            </Row>
            <Row style={{marginTop:"2%"}}>
            <Col md="3">
              <GBOAImageComponent image ={require("assets/art/ScarTheLeopard.jpg").default}
                                  imageSize = "65%"
                                  left  = "20%"
                                  top   = "10%"
                                  width = "200%"
              />
            </Col>
              <Col md="3">
              <GBOAImageComponent image ={require("assets/art/DueDrop.jpg").default}
                                  imageSize = "65%"
                                  left  = "20%"
                                  top   = "10%"
                                  width = "200%"
              />
              </Col>
              <Col md="3">
              <GBOAImageComponent image ={require("assets/art/RodeoHorses.jpg").default}
                                  imageSize = "85%"
                                  left  = "-20%"
                                  top   = "10%"
                                  width = "250%"
              />
              </Col>
              <Col md="3">
              <GBOAImageComponent image ={require("assets/art/BaldEagle.jpg").default}
                                  imageSize = "85%"
                                  left  = "-120%"
                                  top   = "10%"
                                  width = "250%"
              />
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <GBOAImageComponent image ={require("assets/art/MonkeyEagle.jpg").default}
                                    imageSize = "65%"
                                    marginTop = {50}
                                    left  = "20%"
                                    top   = "10%"
                                    width = "250%"
                />
              </Col>
              <Col md="3">
                <Col md="12">
                  <img
                    style={{marginTop:50, width:'65%', borderRadius: 20}}
                    alt="..."
                    src={
                      require("assets/art/VietnameseChild.jpg")
                        .default
                    }
                  ></img>
                </Col>
                <Col md="12" style={{marginTop:10}}>
                  <img
                    style={{marginTop:20, width:'65%', borderRadius: 20}}
                    alt="..."
                    src={
                      require("assets/art/mother.jpg")
                        .default
                    }
                  ></img>
                </Col>
              </Col>
              <Col md="3">
                <img
                  style={{marginTop: 50, width:'65%', borderRadius: 20}}
                  alt="..."
                  src={
                    require("assets/art/SiouxWarrior.jpg")
                      .default
                  }
                ></img>
              </Col>
              <Col md="3">
                <img
                  style={{marginTop: 50, width:'65%', borderRadius: 20}}
                  alt="..."
                  src={
                    require("assets/art/GreatSpirit.jpg")
                      .default
                  }
                ></img>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <GBOAImageComponent image ={require("assets/art/LeopardInTree.jpg").default}
                                    imageSize = "65%"
                                    marginTop = {30}
                                    left  = "20%"
                                    top   = "10%"
                                    width = "200%"
                />
              </Col>
              <Col md="3" style={{marginTop:10}}>
                <GBOAImageComponent image ={require("assets/art/TheHunt.jpg").default}
                                    imageSize = "65%"
                                    marginTop = {20}
                                    left  = "20%"
                                    top   = "10%"
                                    width = "200%"
                />
              </Col>

              <Col md="3">
                <img
                style={{marginTop: 20, width:'75%', borderRadius: 20}}
                  alt="..."
                  src={
                    require("assets/art/Elephants.jpg")
                      .default
                  }
                ></img>
              </Col>
              <Col md="3">
                <img
                style={{marginTop: 20, width:'65%', borderRadius: 20}}
                  alt="..."
                  src={
                    require("assets/art/Leopard.jpg")
                      .default
                  }
                ></img>
              </Col>
            </Row>
            <Row>
                <Col md="3">
                  <Col md="12">
                    <img
                      style={{width:'65%', borderRadius: 20, marginTop:20}}
                      alt="..."
                      src={
                        require("assets/art/LionPair.jpg")
                          .default
                      }
                    ></img>
                  </Col>
                  <Col md="12" style={{marginTop:10}}>
                    <GBOAImageComponent image ={require("assets/art/Baobab.jpg").default}
                                        imageSize = "65%"
                                        marginTop = {20}
                                        left  = "20%"
                                        top   = "10%"
                                        width = "200%"
                    />
                  </Col>
                </Col>
              <Col md="3">
                <img
                  style={{marginTop: 20, width:'65%', borderRadius: 20}}
                  alt="..."
                  src={
                    require("assets/art/MoonLady-waterColour.jpg")
                      .default
                  }
                ></img>
              </Col>
              <Col md="3">
                <Col md="12" style={{marginTop:10}}>
                <img
                  style={{marginTop: 20, width:'65%', borderRadius: 20}}
                  alt="..."
                  src={
                    require("assets/art/Lions.jpg")
                      .default
                  }
                ></img>
                </Col>
                <Col md="12" style={{marginTop:10}}>
                  <img
                  style={{marginTop: 20, width:'65%', borderRadius: 20}}
                    alt="..."
                    src={
                      require("assets/art/Hurricane.jpg")
                        .default
                    }
                  ></img>
                </Col>
              </Col>
              <Col md="3" style={{marginTop:20}}>
                <img
                style={{marginTop: 20, width:'65%', borderRadius: 20}}
                  alt="..."
                  src={
                    require("assets/art/WhiteHorse.jpg")
                      .default
                  }
                ></img>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div style ={{backgroundColor: '#FFFFF',}}>
      <GBOAFooterSocial />
      </div>
    </>
  );

}

export default GBOAPortfolio;
