import React from "react";

// reactstrap components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import emailjs from '@emailjs/browser';
import { Link } from "react-router-dom";

// core components
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter,CustomInput
} from "reactstrap";

function GBOAUpComing() {
  const [first1Focus, setFirst1Focus] = React.useState(false);
  const [last1Focus, setLast1Focus] = React.useState(false);
  const [email1Focus, setEmail1Focus] = React.useState(false);
  const [first2Focus, setFirst2Focus] = React.useState(false);
  const [email2Focus, setEmail2Focus] = React.useState(false);

  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [numberFocus, setNumberFocus] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState('Message Box');
  const [modalTitle, setModalTitle] = React.useState('Some message.');


  React.useEffect(() => {
    document.body.classList.add("contact-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const toggle = () => { setModal(!modal); window.scrollTo(0, 0);
  document.body.scrollTop = 0;}

  const sendEmail = (e) => {
    e.preventDefault();

    if (e.target[0].value === ""){
      setModalTitle("First name error!");
      setModalMessage("A first name has not being entered.");
      toggle();
    }
    else if (e.target[1].value === ""){
      setModalTitle("Last name error!");
      setModalMessage("A last name has not being entered.");
      toggle();
    }
    else if (e.target[2].value === ""){
      setModalTitle("No email address error!");
      setModalMessage("An email has not being entered.");
      toggle();
    }
    else if (e.target[3].checked === false){
      setModalTitle("Could be a robot error!");
      setModalMessage("Robot check has NOT been checked.");
      toggle();
    }
    else{
      emailjs.sendForm('service_91nn8yk', 'template_ekwi3tp', e.target, '1Sw8UQpm6tZCxspv3')
        .then((result) => {
          setModalTitle("Message Sent");
          setModalMessage("We will get back to you shortly.");
          toggle();
        }, (error) => {
            setModalTitle("An error occurred");
            setModalMessage("Please try again" + error.text);
            toggle();
        });
    }

  };


  return (

  <div>
    <div className="section features-7 section-image" style={{
      backgroundImage:
        "url(" + require("assets/img/bg4.jpg").default + ")",
    }} >
      <Container fluid>
        <Row>
          <Col className="text-center ml-auto mr-auto" md="8">
          <img
            style={{width:"30%",borderRadius: 30}}
            alt="..."
            src={require("assets/art/news/ComingUp2.jpg").default}
          ></img>
            <div className="section-description">
            <h2 className="title">News Letter</h2>
            <h6 className="category">'Sneak peak' of up coming art work by Grant Blaise O'Reilly.</h6>
            <h6 className="category">Be the first to see all the latest oil paints before released date.</h6>
            <h5 className="description">
              Simply fill in your name, surname and email address to receive the news letter.
            </h5>
            </div>
          </Col>
          <Col className="ml-auto mr-auto" md="5">
            <Card className="card-contact card-raised">
              <Form id="contact-form1" method="post" role="form" onSubmit={sendEmail}>
                <CardHeader className="text-center">
                  <CardTitle tag="h4">Subscribe to news letter</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="pr-2" md="6">
                      <label>First name</label>
                      <InputGroup
                        className={first1Focus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <FontAwesomeIcon icon="far fa-user"/>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          aria-label="First Name..."
                          autoComplete="given-name"
                          placeholder="First Name..."
                          name = "first_name"
                          type="text"
                          onFocus={() => setFirst1Focus(true)}
                          onBlur={() => setFirst1Focus(false)}
                        ></Input>
                      </InputGroup>
                    </Col>
                    <Col className="pl-2" md="6">
                      <FormGroup>
                        <label>Last name</label>
                        <InputGroup
                          className={last1Focus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <FontAwesomeIcon icon="far fa-circle-user" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            aria-label="Last Name..."
                            autoComplete="family-name"
                            placeholder="Last Name..."
                            name = "last_name"
                            type="text"
                            onFocus={() => setLast1Focus(true)}
                            onBlur={() => setLast1Focus(false)}
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <label>Email address</label>
                    <InputGroup
                      className={email1Focus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FontAwesomeIcon icon="fa-regular fa-envelope" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        autoComplete="email"
                        placeholder="Email Here..."
                        type="email"
                        name = "email"
                        onFocus={() => setEmail1Focus(true)}
                        onBlur={() => setEmail1Focus(false)}
                      ></Input>
                    </InputGroup>
                  </FormGroup>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                          <CustomInput type="checkbox" id="exampleCustomCheckbox" label="I'm not a robot" />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <Button
                        className="btn-round pull-right"
                        color="info"
                        type="submit"
                      >
                        Send Message
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    <Modal style ={{position:'fixed', top: '10%', left: '30%', background:"white"}} isOpen={modal} toggle={toggle}>
        <ModalHeader
            toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>
            {modalMessage}
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={toggle}>Ok</Button>
        </ModalFooter>
    </Modal>
    </div>
  );
}

export default GBOAUpComing;
