import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function BasicComponents() {
  return (
    <>
      <div className="section section-basic-components" data-background-color="dark-blue">

        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="5" md="7">
              <h2 className="title">Virtual Art Gallery</h2>
              <h6 className="category">Art work for sale.</h6>
              <h5 className="description">
                Virtual Gallery is a presentation of all current art pieces created
                by Grant Blaise O'Reilly that are for sale. Virtual Gallery allows
                the client to view the art work and well as gather furthewr information
                about the art pieces.
              </h5>
              <Button
                className="btn-round"
                to="/gboa-product-page"
                color="info"
                tag={Link}
              >
                Virtual Gallery
              </Button>
            </Col>
            <Col lg="6" md="12">
              <div className="image-container">
                <img
                  alt="..."
                  className="components-macbook"
                  src={require("assets/art/BaldEagle.jpg").default}
                ></img>
                <img
                  alt="..."
                  className="table-img"
                  src={
                    require("assets/art/VietnameseChild.jpg").default
                  }
                ></img>
                <img
                  alt="..."
                  className="share-btn-img"
                  src={
                    require("assets/art/SiouxWarrior.jpg")
                      .default
                  }
                ></img>
                <img
                  alt="..."
                  className="coloured-card-btn-img"
                  src={
                    require("assets/art/MonkeyEagle.jpg")
                      .default
                  }
                ></img>
                <img
                  alt="..."
                  className="coloured-card-img"
                  src={
                    require("assets/art/RodeoHorses.jpg")
                      .default
                  }
                ></img>
                <img
                  alt="..."
                  className="social-img"
                  src={
                    require("assets/art/LionPair.jpg")
                      .default
                  }
                ></img>
                <img
                  alt="..."
                  className="linkedin-btn-img"
                  src={
                    require("assets/art/MoonLady-waterColour.jpg")
                      .default
                  }
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default BasicComponents;
