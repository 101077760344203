import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import Rellax from "rellax";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import GBOAScrollTransparentHeader from "components/Navbars/GBOAScrollTransparentHeader.js";
import GBOAShowRoomHeader from "components/Headers/GBOAShowRoomHeader.js";
import GBOAFooterSocial from "components/Footers/GBOAFooterSocial.js";
import StudioBasicComponents from "../presentation-sections/StudioBasicComponents.js";
import GBOAUpComing from "../presentation-sections/GBOAUpComing.js";
import ShowRoomOne from "../showrooms/showroom-1.js";

function GBOAShowRoom() {

  const [specialitySelect, setSpecialitySelect] = React.useState(null);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    return function cleanup() {
      document.body.classList.remove("presentation-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ScrollTransparentNavbar />
      <GBOAShowRoomHeader />
      <ShowRoomOne />

      <div className="wrapper">
        <div className="separator-line bg-info"></div>

        <div style ={{backgroundColor: '#FFFFF',}}>
           <GBOAFooterSocial />
        </div>
      </div>
    </>
  );
}

export default GBOAShowRoom;
