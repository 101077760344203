/*
================================================================================
Grant Blaise O'Reilly's Art Component
Ausphere product 2023-03-02
Developed by Grant O'Reilly
================================================================================
*/
import React, {useState, useEffect, useContext} from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardLink,
  CardTitle,
  Collapse,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

import {
  ADD_CART_ITEM,
  REMOVE_CART_ITEM,
  UPDATE_CART_ITEM_COUNT,
} from "../../components/ShoppingCart/Services/Cart/action-types";

import {CartContext } from "../../components/ShoppingCart/Services/Cart/CartContext";


const showButtons = (itemsFor, next, previous) =>{
  if (itemsFor.length <= 1){
    return(<></>)
  }
  else{
    return(
      <>
      <a
        className="carousel-control-prev"
        data-slide="prev"
        href="#pablo"
        onClick={(e) => {
          e.preventDefault();
          previous();
        }}
        role="button"
      >
        <Button
          className="btn-icon btn-round"
          color="info"
          name="button"
          size="sm"
          type="button"
        >
          <FontAwesomeIcon icon="fa-solid fa-circle-arrow-left" />
        </Button>
      </a>
      <a
        className="carousel-control-next"
        data-slide="next"
        href="#pablo"
        onClick={(e) => {
          e.preventDefault();
          next();
        }}
        role="button"
      >
        <Button
          className="btn-icon btn-round"
          color="info"
          name="button"
          size="sm"
          type="button"
        >
          <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
        </Button>
      </a>
      </>
    );
  }
}

// function GBOAPainting(name,
//                       artist,
//                       artistComment,
//                       paintingType,
//                       image,
//                       price,
//                       buttons,
//                       sold,
//                       description,
//                       artistInfo,
//                       itemsFor,
//                       marginTop,
//                       product){
//extractImages(product_item.images),

function GBOAPainting(product){

  // collapse states and functions
  const { cartItem, dispatch } = useContext(CartContext);
  //const [collapses, setCollapses] = React.useState([1]);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const [addCart, setAddCart] = React.useState(false);



  // const changeCollapse = (collapse) => {
  //   if (collapses.includes(collapse)) {
  //     setCollapses(collapses.filter((prop) => prop !== collapse));
  //   } else {
  //     setCollapses([...collapses, collapse]);
  //   }
  // };

  const onExiting = () => {
    setAnimating(false);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === itemsFor.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? itemsFor.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const history = useHistory();

  const routeChange = () =>{
    //let path = "https://www.facebook.com/grantblaiseoreillyart/shop_tab";
    let path = "https://www.facebook.com/grantblaiseoreillyart";
    window.open(path, '_blank');
  }

  const routeChangeSaatchi = () =>{
    let path = "https://www.saatchiart.com/account/profile/2213487";
    window.open(path, '_blank');
  }
  const extractImages = (images) => {
      let imageList = [];
      let count = 0;
      images.map((image) =>{
         if (count !== 1) //This is the ThumbNail displayImage
          imageList.push({ src: require("assets/art/"+image.filename).default, altText: "", caption: ""})
         count++;
      }
    )
    return imageList;
  }

  const addToCart = (item) => {
    setAddCart(true);
    item.isAddedtoCart = true;
    item.count = 1;
    dispatch({
      type: ADD_CART_ITEM,
      payload: item,
    });
  };

  const removeFromCart = (item) => {
    setAddCart(false);
    item.isAddedtoCart = false;
    item.count = 0;
    dispatch({
      type: REMOVE_CART_ITEM,
      payload: item,
    });
  };

  const isInCart = (item) => {
     let found = false;
     cartItem.map((prod) => {
       if (item.name === prod.name){
          found = true;
        }
      }
     );
     return found;
  }


  // <Button
  //      style={{padding:'5px', width:120}}
  //      color="info"
  //      onClick={routeChangeSaatchi}
  //    >
  //    <img
  //      alt="..."
  //      src={require("assets/img/saatchiArt-logo.png").default}
  //    ></img>
  //  </Button>

  const shopButtons = () =>{
    if (product.sold === false){
      if (product.shop === "facebookshop" ){
        return(
           <Row className="justify-content-end">
           <Button
                style={{padding:'5px', width:120}}
                color="info"
                onClick={routeChange}
              >
              <img
                alt="..."
                src={require("assets/img/facebookshop.png").default}
              ></img>
            </Button>
             <Button
                     className="mr-3"
                     color="info"
                     to={{pathname: "/painting-contact-us",
                          state: { image : product.image,
                                   price : product.price,
                                   name  : product.name}
                        }}
                     tag={Link}>
               Enquire  
               <FontAwesomeIcon icon="fa-regular fa-envelope" />
             </Button>
           </Row>
        )
      }
      else if (product.shop === "gboashop" ) {

        let val = isInCart(product);

        if (val){
            return(
               <Row className="justify-content-end">
                <Button
                        style={{padding:'10px', width:180}}
                        color="primary"
                        to={{pathname: "/painting-contact-us",
                             state: { image : product.images[1].filename,
                                      price : product.price,
                                      name  : product.name}
                           }}
                        onClick={() => removeFromCart({"id": product.id, "name":product.name, "sold":product.sold, "price":product.price, "priceValue":product.priceValue, "smallImage": require("assets/art/"+product.images[1].filename).default })}>
                        Remove from Cart
                        <FontAwesomeIcon icon="fa-solid fa-trash-can" style={{marginLeft:10, fontSize: '20'}}/>
                </Button>
               </Row>
            )
        }
        else{

                if (addCart){
                  return(
                     <Row className="justify-content-end">
                      <Button
                              style={{padding:'10px', width:180}}
                              color="primary"
                              to={{pathname: "/painting-contact-us",
                                   state: { image : product.images[1].filename,
                                            price : product.price,
                                            name  : product.name}
                                 }}
                              onClick={() => removeFromCart({"id": product.id, "name":product.name, "sold":product.sold, "price":product.price, "priceValue":product.priceValue, "smallImage": require("assets/art/"+product.images[1].filename).default })}>
                              Remove from Cart
                              <FontAwesomeIcon icon="fa-solid fa-trash-can" style={{marginLeft:10, fontSize: '20'}}/>
                      </Button>
                     </Row>
                  )
                }
                else{
                  return(
                     <Row className="justify-content-end">
                      <Button
                              style={{padding:'10px', width:125}}
                              color="success"
                              to={{pathname: "/painting-contact-us",
                                   state: { image : require("assets/art/"+product.images[1].filename).default ,
                                            price : product.price,
                                            name  : product.name}
                                 }}
                              //onClick={() => addToCart({"id": product.id, "name":product.name, "sold":product.sold, "price":product.price, "priceValue":product.priceValue, "smallImage": require("assets/art/"+product.images[1].filename).default })}>
                              onClick={() => addToCart(product)}>
                              Add to Cart
                              <FontAwesomeIcon icon="fa-solid fa-cart-plus" style={{marginLeft:10, fontSize: '20'}}/>
                      </Button>
                       <Button
                               style={{padding:'10px', width:120}}
                               color="info"
                               to={{pathname: "/painting-contact-us",
                                    state: { image : product.images[0].filename,
                                             price : product.price,
                                             name  : product.name}
                                  }}
                               tag={Link}>
                         Enquire  
                         <FontAwesomeIcon icon="fa-regular fa-envelope" style={{marginLeft:10, fontSize: '20'}}/>
                       </Button>
                     </Row>
                  )
                }
        }
      }
      else
      return(
        <Row className="justify-content-end">
          <Button
                  className="mr-3"
                  color="info"
                  to={{pathname: "/painting-contact-us",
                       state: { image : product.images[0].filename,
                                price : product.price,
                                name  : product.name}
                     }}
                  tag={Link}>
            Enquire  
            <FontAwesomeIcon icon="fa-regular fa-envelope" />
          </Button>
        </Row>
      );
    }
    else{
      return(
        <Row className="justify-content-end">
             <img
               style={{width:'120px', marginTop:-30}}
               alt="..."
               src={require("assets/img/sold.png").default}
             ></img>
        </Row>
      );
    }

  }


  const priceRequest = () => {
    if (product.price === "Price on request" ){
      return ( <h3 className="main-price">
              {product.price}
              </h3>
             )
    }
    else
        return (
               <h3 className="main-price" style={{color: 'orange', fontWeight: 'bold'}}>
                <img
                  alt="..."
                  style={{width:"32px", marginRight: 10}}
                  src={require("assets/logo/australia.png").default}
                ></img>
                {product.price}
                </h3>
              )
  }

  const itemsFor = extractImages(product.images);
  const paintingType = product.medium +" "+product.size;

  return (

  <Container >
      <div className="section-story-overview">
        <Row>
        <Col md="5" style={{marginTop:product.marginTop}}>
             <Carousel
               activeIndex={activeIndex}
               next={next}
               previous={previous}
             >
               <CarouselIndicators
                 items={itemsFor}
                 activeIndex={activeIndex}
                 onClickHandler={goToIndex}
               />
               {itemsFor.map((item) => {
                 return (
                   <CarouselItem
                     onExiting={onExiting}
                     onExited={onExited}
                     key={item.src}
                   >
                     <img
                       style={{borderRadius: 20}}
                       src={item.src}
                       alt={item.altText}
                       className="d-block img-raised"
                     />
                   </CarouselItem>
                 );
               })}

               {showButtons(itemsFor, next, previous)}

             </Carousel>
           </Col>
          <Col className="ml-auto mr-auto" md="6">
            <h2 className="title">{product.name}</h2>
            <h5 className="category">{paintingType}</h5>
            {priceRequest()}

            <div style={{border: '1px',borderStyle: 'solid',  borderColor:'#2CA8FF', color: '#2CA8FF',padding: '20px',
                        fontSize: '1.1em' }}>
                {product.description} <br></br>
                <hr></hr>
                <small style = {{fontSize: '0.8571em', textTransform: 'uppercase'}}>{product.artist}</small>
            </div>
            {shopButtons()}

          </Col>
        </Row>
      </div>
  </Container>
);
}

export default GBOAPainting;
