import React from "react";

// reactstrap components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import emailjs from '@emailjs/browser';
import { Link } from "react-router-dom";

// core components
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter,CustomInput
} from "reactstrap";

function ShowRoomOne() {

  React.useEffect(() => {
    document.body.classList.add("contact-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);


  return (

  <div>
      <Container fluid>
          <Col className="text-center ml-auto mr-auto" md="6">
          <img
            style={{width:"100%",borderRadius: 30, marginTop:30}}
            alt="..."
            src={require("assets/art/showroom/Gahana-Showroom-04.jpg").default}
          ></img>
          </Col>
          <Col className="text-center ml-auto mr-auto" md="3">
            <img
              style={{width:"100%",borderRadius: 30, marginTop:30}}
              alt="..."
              src={require("assets/art/showroom/Ukukhanya-Showroom-01.jpg").default}
            ></img>
          </Col>
          <Col className="text-center ml-auto mr-auto" md="3">
            <img
              style={{width:"100%",borderRadius: 30, marginTop:30}}
              alt="..."
              src={require("assets/art/showroom/Gahana-Showroom-02.jpg").default}
            ></img>
          </Col>
          <Col className="text-center ml-auto mr-auto" md="6">
          <img
            style={{width:"100%",borderRadius: 30, marginTop:30}}
            alt="..."
            src={require("assets/art/showroom/Ukukhanya-Showroom-02.jpg").default}
          ></img>
          </Col>
          <Col className="text-center ml-auto mr-auto" md="6">
          <img
            style={{width:"100%",borderRadius: 30, marginTop:30}}
            alt="..."
            src={require("assets/art/showroom/Gahana-Showroom-03.jpg").default}
          ></img>
          </Col>
          <Col className="text-center ml-auto mr-auto" md="6">
          <img
            style={{width:"100%",borderRadius: 30, marginTop:30}}
            alt="..."
            src={require("assets/art/showroom/Gahana-Showroom-01.jpg").default}
          ></img>
          </Col>
      </Container>
    </div>
  );
}

export default ShowRoomOne;
