import { useState,useEffect, useContext } from 'react';
import GBOAPrintingProduct from "../../../../views/examples/GBOAPrintingProduct2.js";
import printsData from "./printingItems.json";

export default function GBOAProductsList() {

  const [prints, setPrints] = useState(printsData);

  return (
      <div>
        {prints.map(product_item => (
          GBOAPrintingProduct(product_item)
        ))}
      </div>
  );
}
