import React from "react";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";


// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import GBOAScrollTransparentHeader from "components/Navbars/GBOAScrollTransparentHeader.js";
import GBOAAboutUsHeader from "components/Headers/GBOAAboutUsHeader.js";
import GBOAFooterSocial from "components/Footers/GBOAFooterSocial.js";
import GBOAUpComing from "../presentation-sections/GBOAUpComing.js";


function GBOAAboutUs() {

  const [specialitySelect, setSpecialitySelect] = React.useState(null);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const history = useHistory();


  const routeChangeGBO = () =>{
    let path = "https://www.facebook.com/grantblaise.oreilly";
    window.open(path, '_blank');
  }

  const routeChangeTikTok = () =>{
    let path = "https://www.tiktok.com/@grantblaise";
    window.open(path, '_blank');
  }
  
  const routeChangeLinkedin = () =>{
    let path = "https://www.linkedin.com/in/grantboreilly/";
    window.open(path, '_blank');
  }

  const routeChangeGoogle = () =>{
    let path = "https://scholar.google.com/citations?user=3K0mLSIAAAAJ&hl=en&oi=ao";
    window.open(path, '_blank');
  }

  const routeChangeFaceBook = () =>{
    let path = "https://www.facebook.com/grantblaiseoreillyart";
    window.open(path, '_blank');
  }

  const routeChangeInstagram = () =>{
    let path = "https://www.instagram.com/grantblaiseart/";
    window.open(path, '_blank');
  }


  return (

    <>
    <ScrollTransparentNavbar />
    {/*GBOAScrollTransparentHeader(true)*/}
    <GBOAAboutUsHeader/>

    <div className="section">
       <div className="about-description text-center">
         <div>
           <Container>
             <Row>
               <Col className="mr-auto ml-auto" md="8">
                 <h2 className="title">Own a Grant Blaise O'Reilly original oil painting.</h2>
                 <h4 className="description">
                   Ever wanted to own your personal realistic oil painting at a reasonable price?
                 </h4>
                 <h4 className="description">
                   Here is a once in a lifetime opportunity to own your unique original oil painting created buy Grant Blaise O'Reilly.
                 </h4>
                 <h4 className="description">
                   At Grant Blaise O'Reilly's art we create original oil paintings. Wild life, portraits and many more themed oil paintings.
                 </h4>
                 <h4 className="description">
                   Grant Blaise O'Reilly's art is also open to commissioned oil paintings.
                 </h4>
               </Col>
             </Row>
             <Row>
               <Col md="4">
                 <div className="info info-hover">
                   <div className="icon icon-success icon-circle">
                     <FontAwesomeIcon icon="fa-solid fa-envelopes-bulk" style={{fontSize:50, marginTop:15}}/>
                   </div>
                   <h4 className="info-title">Contact Us</h4>
                   <p className="description">
                     Simple hit the contact button.
                   </p>
                 </div>
               </Col>
               <Col md="4">
                 <div className="info info-hover">
                   <div className="icon icon-info icon-circle">
                     <FontAwesomeIcon icon="fa-regular fa-envelope" style={{fontSize:50, marginTop:15}}/>
                   </div>
                   <h4 className="info-title">Email us directly</h4>
                   <p className="description">
                     <br></br>
                     grantblaiseoreilly@gmail.com
                   </p>
                 </div>
               </Col>
               <Col md="4">
                 <div className="info info-hover">
                   <div className="icon icon-primary icon-circle">
                     <FontAwesomeIcon icon="fa-solid fa-phone-volume" style={{fontSize:50, marginTop:15}} />
                   </div>
                   <h4 className="info-title">Give me a call</h4>
                   <p className="description">
                     Grant Blaise O'Reilly <br></br>
                     +61 426 892 715 <br></br>
                     Mon - Fri, 8:00-22:00 (AEST Brisbane)
                   </p>
                 </div>
               </Col>
             </Row>
           </Container>
         </div>
       </div>
       <div className="separator-line bg-info"></div>
       <div className="projects-5">
         <Container>
           <Row>
             <Col className="ml-auto mr-auto text-center" md="8">
               <h2 className="title">Why own a Grant Blaise O'Reilly oil painting?</h2>
               <h4 className="description">
                 Investment, decoration, family heirloom
               </h4>
               <div className="section-space"></div>
             </Col>
           </Row>
           <Row>
             <Col className="ml-auto" md="5">
               <Card
                 className="card-background card-background-product card-raised"
                 style={{
                   backgroundImage:
                     "url(" +
                     require("assets/art/DueDrop.jpg").default +
                     ")",
                 }}
               >
                 <CardBody>
                   <CardTitle tag="h2">The pleasure of owning an oil painting</CardTitle>
                   <div className="info info-horizontal">
                     <div className="icon icon-info">
                       <FontAwesomeIcon icon="fa-solid fa-palette" style={{fontSize:20}} />
                       <FontAwesomeIcon icon="fa-solid fa-paintbrush" style={{fontSize:20}} />
                     </div>
                     <div className="description" >
                       <h3 className="info-title" style={{marginTop:15, color:"white"}}>
                        Uniqueness
                       </h3>
                       <p className="card-description"  style={{fontWeight: "bold"}}>
                         Oil paintings are a unique way to bring art into your home.
                       </p>
                     </div>
                   </div>
                   <div className="info info-horizontal">
                     <div className="icon icon-info">
                       <FontAwesomeIcon icon="fa-solid fa-palette" style={{fontSize:20}} />
                       <FontAwesomeIcon icon="fa-solid fa-paintbrush" style={{fontSize:20}} />
                     </div>
                     <div className="description" >
                       <h3 className="info-title" style={{marginTop:15, color:"white"}}>
                        Quality
                       </h3>
                       <p className="card-description"  style={{fontWeight: "bold"}}>
                          Oil paintings are different than other types of artwork because the paint is made up of pigments that have been mixed with oil, giving it an intense color and long-lasting quality.
                       </p>
                     </div>
                   </div>
                   <div className="info info-horizontal">
                     <div className="icon icon-info">
                       <FontAwesomeIcon icon="fa-solid fa-palette" style={{fontSize:20}} />
                       <FontAwesomeIcon icon="fa-solid fa-paintbrush" style={{fontSize:20}} />
                     </div>
                     <div className="description" >
                       <h3 className="info-title" style={{marginTop:15, color:"white"}}>
                        Benefits
                       </h3>
                       <p className="card-description"  style={{fontWeight: "bold"}}>
                         Displaying an original oil painting in your home creates a warm amdiance and it is a great coversation piece.
                         Adding beauty and style your home or office. An investing that will always appreciate in value and it is an asset you will cherish for years to come.                    </p>
                     </div>
                   </div>
                 </CardBody>
               </Card>
             </Col>
             <Col className="mr-auto" md="5">
               <div className="info info-horizontal">
                 <div className="icon icon-info">
                   <FontAwesomeIcon icon="fa-regular fa-heart" style={{fontSize:30, marginTop:5}} />
                 </div>
                 <div className="description">
                   <h4 className="info-title">
                    Invest in what you enjoy
                   </h4>
                   <p className="description">
                     Oil paintings can be a great investment.
                     If you enjoy looking at art, it's worth buying some oil paintings to put in your home or office space.
                     It will give you aesthetic pleasure and is a good way of decorating the room.
                     It also gives people something nice to look at when they visit your house as well.
                     If you have art that speaks to your heart then it’s worth investing in.
                   </p>
                 </div>
               </div>
               <div className="info info-horizontal">
                 <div className="icon icon-info">
                   <FontAwesomeIcon icon="fa-solid fa-street-view"   style={{fontSize:30, marginTop:5}}  />
                 </div>
                 <div className="description">
                   <h4 className="info-title">Consider where the oil painting will be displayed.</h4>
                   <p className="description">
                     For this, consider whether it is a small painting suitable for display on the wall or a large one that requires to be hung from floor to ceiling.
                     Also, think about where you will keep your oil paintings once they have been removed from their frames and placed in storage. If possible,
                     choose an appropriate spot based on size so all of them can fit snugly together without being crowded into too tight a space.
                     Oil Paintings are expensive items that need proper care when displaying.
                     To ensure long life and good appearance don't place oil painting next to radiators as heat causes dryness and cracking in paint surfaces.
                   </p>
                 </div>
               </div>
               <div className="info info-horizontal">
                 <div className="icon icon-info">
                   <FontAwesomeIcon icon="fa-solid fa-sack-dollar"  style={{fontSize:30, marginTop:5}}  />
                 </div>
                 <div className="description">
                   <h4 className="info-title">We will cater for your budget</h4>
                   <p className="description">
                     One of our goals is to allow everyone to enjoy the pleasure of owning a 'one of a kind' original oil painting.
                     That they can own and enjoy for rest of their life. So, we will try and cater, within reason, to every clients budget.
                   </p>
                 </div>
               </div>
             </Col>
           </Row>
           <hr></hr>
           <Row>
             <Col className="ml-auto mr-auto text-center" md="8">
               <h2 className="title">Themes of Grant Blaise O'Reilly art</h2>
               <h4 className="description">
                 As I was born in South Africa, the raw wildness of African and its wildlife run deep in my veins.
                 So, naturally wildlife art and the passion of painting a beautiful, wild animal naturally bubbles out when I approach an oil painting.
                 In most of my artwork I haver tried to capture forms of realism and hyperrealism.
               </h4>
               <div className="section-space"></div>
             </Col>
           </Row>
           <Row>
             <Col className="ml-auto mt-5" md="5">
               <div className="info info-horizontal">
                 <div className="icon icon-warning">
                   <FontAwesomeIcon icon="fa-solid fa-palette" style={{fontSize:20, marginTop:12}} />
                   <FontAwesomeIcon icon="fa-solid fa-paintbrush" style={{fontSize:20, marginTop:12}}/>
                 </div>
                 <div className="description">
                   <h4 className="info-title">Wildlife Art</h4>
                   <p className="description">
                     As wildlife is something very close to my heart one of the core themes to my art work is naturally wildlife.
                   </p>
                 </div>
               </div>
               <div className="info info-horizontal">
                 <div className="icon icon-warning">
                 <FontAwesomeIcon icon="fa-solid fa-palette" style={{fontSize:20, marginTop:12}} />
                 <FontAwesomeIcon icon="fa-solid fa-paintbrush" style={{fontSize:20, marginTop:12}}/>
                 </div>
                 <div className="description">
                   <h4 className="info-title">Realism</h4>
                   <p className="description">
                      Realism is the accurate, detailed, unembellished depiction of nature or of contemporary life.
                      Imaginative idealization is rejected in favour of a close observation of outward appearances.
                   </p>
                 </div>
               </div>
               <div className="info info-horizontal">
                 <div className="icon icon-warning">
                 <FontAwesomeIcon icon="fa-solid fa-palette" style={{fontSize:20, marginTop:12}} />
                 <FontAwesomeIcon icon="fa-solid fa-paintbrush" style={{fontSize:20, marginTop:12}}/>
                 </div>
                 <div className="description">
                   <h4 className="info-title">Hyperrealism</h4>
                   <p className="description">
                     This a genre of painting resembling a high-resolution photograph.
                     I have tried to capture some form of hyperrealismlot in the recent artwork that I have produced.
                   </p>
                 </div>
               </div>
             </Col>
             <Col className="mr-auto" md="5">
               <Card
                 className="card-background card-background-product card-raised"
                 style={{
                   backgroundImage:
                     "url(" +
                     require("assets/img/project9.jpg").default +
                     ")",
                 }}
               >
                 <CardBody>
                   <CardTitle tag="h1">What I love to paint</CardTitle>
                   <Badge className="badge-info">Wildlife</Badge>
                   <Badge className="badge-info" style={{marginLeft:20}}>People</Badge>
                   <Badge className="badge-info" style={{marginLeft:20}}>Nature</Badge>
                   <Badge className="badge-info" style={{marginLeft:20}}>Horses</Badge>
                    <div className="separator-line bg-info"></div>
                   <CardTitle tag="h1">
                     Genres
                   </CardTitle>
                   <Badge className="badge-success" style={{marginLeft:20}}>Realism</Badge>
                   <Badge className="badge-success" style={{marginLeft:20}}>Hyperrealism</Badge>
                 </CardBody>
               </Card>
             </Col>
           </Row>
         </Container>
       </div>
       <div className="about-team team-4">
         <Container>
           <Row>
             <Col className="ml-auto mr-auto text-center" md="8">
               <h2 className="title">Artist</h2>
               <h4 className="description">
                 The artwork I create is a pure passion of mine and an escape to modern day living.
               </h4>
               <h4 className="description">
                 I was born in South Africa and spent 45 years living in Africa. Then, in 2013 I immigrated to beautiful Australia.
                 I'm now an Australian citizan in particular a Queenslander. My home the Sunshine Coast.
               </h4>
               <h4 className="description">
                 I have a doctorate degree (Phd.) in computer science and two masters degrees (MSc.) in Physics and computer science, respectively.
                 I work on the Sunshine Coast, Queensland as a computer scientist.
               </h4>
             </Col>
           </Row>
           <Row>
             <Col className="ml-auto mr-auto" lg="7" xl="6">
               <Card className="card-profile card-plain">
                 <Row>
                   <Col md="5">
                     <div className="card-image">
                       <a href="#pablo" onClick={(e) => e.preventDefault()}>
                         <img
                           alt="..."
                           className="img img-raised rounded"
                           src={require("assets/logo/GBO-01.jpg").default}
                         ></img>
                       </a>
                     </div>
                   </Col>
                   <Col md="7">
                     <CardBody>
                       <CardTitle tag="h4">Grant Blaise O'Reilly PhD.</CardTitle>
                       <h6 className="category">Scientist and Artist</h6>
                       <p className="card-description">
                         The more I discover and educate myself the more I realise how little I know.
                       </p>
                       <CardFooter>
                         <Button
                           className="btn-icon btn-round"
                           color="info"
                           onClick={routeChangeGBO}
                         >
                           <FontAwesomeIcon icon="fab fa-facebook-square"/>
                         </Button>
                         <Button
                           className="btn-icon btn-round"
                           style={{marginLeft:10}}
                           color="info"
                           onClick={routeChangeLinkedin}
                         >
                           <FontAwesomeIcon icon="fab fa-linkedin"/>
                         </Button>
                         <Button
                           className="btn-icon btn-round"
                           style={{marginLeft:10}}
                           color="info"
                           onClick={routeChangeGoogle}
                         >
                           <FontAwesomeIcon icon="fab fa-google"/>
                         </Button>
                       </CardFooter>
                     </CardBody>
                   </Col>
                 </Row>
               </Card>
             </Col>
           </Row>
         </Container>
       </div>
       <div className="about-office">
         <Container>
           <Row className="text-center">
             <Col className="ml-auto mr-auto" md="8">
               <h2 className="title">Makeshift studio (study) at home</h2>
               <h4 className="description">
                 My makeshift studio is my study at home.
               </h4>
             </Col>
           </Row>
           <Row>
             <Col md="4">
               <img
                 alt="..."
                 className="rounded img-raised"
                 src={require("assets/art/news/Scar/Scar-08.jpg").default}
               ></img>
             </Col>
             <Col md="4">
               <img
                 alt="..."
                 className="rounded img-raised"
                 src={require("assets/art/about/about-03.jpg").default}
               ></img>
             </Col>
             <Col md="4">
               <img
                 alt="..."
                 className="rounded img-raised"
                 src={require("assets/art/display/scar-display-01.jpg").default}
               ></img>
             </Col>
             <Col md="4">
               <img
                 alt="..."
                 className="rounded img-raised"
                 src={require("assets/art/about/about-01.jpg").default}
               ></img>
             </Col>
             <Col md="4">
               <img
                 alt="..."
                 className="rounded img-raised"
                 src={require("assets/art/about/about-02.jpg").default}
               ></img>
             </Col>
             <Col md="4">
               <img
                 alt="..."
                 className="rounded img-raised"
                 src={require("assets/art/about/about-05.jpg").default}
               ></img>
             </Col>
           </Row>
         </Container>
       </div>

     </div>
     <>
       <GBOAUpComing />
       <div
         className="section features-7 section-image"
         style={{height:'550px',
           backgroundImage:
             "url(" + require("assets/img/presentation-page/follow-me-facebook-button-keyboard.png").default + ")",
         }}
       >
         <Container fluid>
           <Row>
             <Col className="ml-auto mr-auto" lg="5" md="7" style={{marginTop:5}}>
               <h2 className="title">Follow Me on Facebook and Instagram</h2>
               <h6 className="category">All my artwork, recent releases and sneak peeks are on Facebook and Instagram.</h6>
               <hr></hr>

                 <Button
                   className="btn-round btn-icon mr-1"
                   color="facebook"
                   size="lg"
                   onClick={routeChangeFaceBook}
                 >
                   <FontAwesomeIcon icon="fab fa-facebook-square" style={{fontSize:30}}/>
                 </Button>
                 <Button
                   className="btn-icon btn-round mr-1"
                   style={{marginLeft:10}}
                   color="instagram"
                   size="lg"
                   onClick={routeChangeInstagram}
                 >
                 <img
                   alt="..."
                   className="img img-raised rounded"
                   src={require("assets/img/instagram.png").default}
                 ></img>
                 </Button>
                 <Button
                   className="btn-icon btn-round mr-1"
                   style={{marginLeft:10}}
                   color="instagram"
                   size="lg"
                   onClick={routeChangeTikTok}
                 >
                     <img
                       alt="..."
                       className="img img-raised rounded"
                       src={require("assets/img/tiktok.png").default}
                     ></img>
                 </Button>
             </Col>
           </Row>
         </Container>
       </div>
     </>
    <GBOAFooterSocial />

    </>
  );
}

export default GBOAAboutUs;
