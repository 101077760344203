import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function StudioBasicComponents(props) {

  const drawButton = () => {
    if (props.viewButton === "false"){
      return "";
    } else{
      return(
        <Button
          className="btn-round"
          to="/news"
          color="info"
          tag={Link}
        >
          Studio
        </Button>
      )
  }
  }

  return (
    <>
      <div className="section section-basic-components" data-background-color="dark-blue">

        <Container>
          <Row>
            <Col lg="6" md="12">
              <div className="image-container">
                <img
                  alt="..."
                  className="components-macbook"
                    style={{width:'80%', borderRadius: 20}}
                  src={require("assets/art/DueDrop.jpg").default}
                ></img>
                <img
                  alt="..."
                  className="table-img"
                  src={
                    require("assets/art/news/tiger-06.jpg").default
                  }
                ></img>
                <img
                  alt="..."
                  className="share-btn-img"
                  src={
                    require("assets/art/news/tiger-05.jpg")
                      .default
                  }
                ></img>
                <img
                  alt="..."
                  className="coloured-card-btn-img"
                  src={
                    require("assets/art/news/tiger-04.jpg")
                      .default
                  }
                ></img>
                <img
                  alt="..."
                  className="coloured-card-img"
                  src={
                    require("assets/art/news/tiger-03.jpg")
                      .default
                  }
                ></img>
                <img
                  alt="..."
                  className="social-img"
                  src={
                    require("assets/art/news/tiger-02.jpg")
                      .default
                  }
                ></img>
                <img
                  alt="..."
                  className="linkedin-btn-img"
                  src={
                    require("assets/art/news/tiger-01.jpg")
                      .default
                  }
                ></img>
              </div>
            </Col>
            <Col className="ml-auto mr-auto" lg="5" md="7">
              <h2 className="title">Current Art Work</h2>
              <h6 className="category">Art being created.</h6>
              <h5 className="description">
                Studio is a presentation of all current art pieces being created
                by Grant Blaise O'Reilly. Studio gives the client the oppertunity
                to observe the overall process of creating an art piece. The client
                can also see the up and coming pieces.
              </h5>
              {drawButton()}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default StudioBasicComponents;
