import React from "react";

// reactstrap components
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Container,Row, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// core components

function SocialSiteButtons() {
  let pageHeader = React.createRef();

  const history = useHistory();

  const routeChangeFaceBook = () =>{
    let path = "https://www.facebook.com/grantblaiseoreillyart";
    window.open(path, '_blank');
  }

  const routeChangeTikTok = () =>{
    let path = "https://www.tiktok.com/@grantblaise";
    window.open(path, '_blank');
  }

  const routeChangeInstagram = () =>{
    let path = "https://www.instagram.com/grantblaiseart/";
    window.open(path, '_blank');
  }

  return (
          <div style={{position: 'absolute', top: '95%', left: '45%'}} >

            <Button
              className="btn-icon btn-round mr-1"
              color="info"
              onClick={routeChangeFaceBook}
            >
              <FontAwesomeIcon icon="fab fa-facebook-square"/>
            </Button>
            <Button
              className="btn-icon btn-round mr-1"
              style={{marginLeft:10}}
              color="info"
              onClick={routeChangeInstagram}
            >
              <FontAwesomeIcon icon="fa-brands fa-instagram"/>
            </Button>
            <Button
              className="btn-icon btn-round mr-1"
              style={{marginLeft:10}}
              color="info"
              onClick={routeChangeTikTok}
            >
              <FontAwesomeIcon icon="fa-brands fa-tiktok"/>
            </Button>
      </div>

  );
}

export default SocialSiteButtons;
