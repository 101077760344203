/*
================================================================================
Grant Blaise O'Reilly's Art Component
Ausphere product 2023-03-02
Developed by Grant O'Reilly
================================================================================
*/
import React, { useReducer, useState, useContext } from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardLink,
  CardTitle,
  Collapse,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import GBOAScrollTransparentHeader from "components/Navbars/GBOAScrollTransparentHeader.js";
import ProductPageHeader from "components/Headers/ProductPageHeader.js";
import GBOAFooterSocial from "components/Footers/GBOAFooterSocial.js";
import GBOAPainting from "./GBOAPaintingsCarousel.js";
import GBOAProductList from '../../components/ShoppingCart/Components/GBOAProducts/GBOAProductList';


function GBOAProductPage() {

  const dollarType = " (AUD)";
  // carousel states and functions

  const [modal, setModal] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState('Message Box');
  const [modalTitle, setModalTitle] = React.useState('Some message.');
  const toggle = () => setModal(!modal);


  // collapse states and functions
  const [collapses, setCollapses] = React.useState([1]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  // select states and functions
  const [colorSelect, setColorSelect] = React.useState(null);
  const [sizeSelect, setSizeSelect] = React.useState(null);

  React.useEffect(() => {
    document.body.classList.add("product-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("product-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);


  // <ProductList>
  // </ProductList>

//<GBOAProductList />



  return (
    <>
      <ScrollTransparentNavbar cartVisible = {true} />
      {/*GBOAScrollTransparentHeader(true)*/}
      <div className="wrapper">
        <ProductPageHeader />
        <div className="section">
          <Container>
            <div className="section">
             <GBOAProductList />
            </div>
          </Container>
        </div>
        <GBOAFooterSocial />
      </div>
    </>
  );
}

export default GBOAProductPage;

/*

*/
