/*
================================================================================
Grant Blaise O'Reilly's Art Component
Ausphere product 2023-03-02
Developed by Grant O'Reilly
================================================================================
*/

/*eslint-disable*/
import React from "react";
import { Link,useHistory  } from "react-router-dom";
import { Button, Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// reactstrap components

// core component

function HomePresentationHeader() {

  const history = useHistory();

  const routeChangeTikTok = () =>{
    let path = "https://www.tiktok.com/@grantblaise";
    window.open(path, '_blank');
  }

  const routeChangeFaceBook = () =>{
    let path = "https://www.facebook.com/grantblaiseoreillyart";
    window.open(path, '_blank');
    //history.push(path);
  }

  const routeChangeInstagram = () =>{
    let path = "https://www.instagram.com/grantblaiseart/";
    window.open(path, '_blank');
    //history.push(path);
  }


  return (
    <>
      <div className="page-header clear-filter">

        <div className="rellax-header rellax-header-sky" data-rellax-speed="-4">

          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/presentation-page/GBOA-Home.jpg")
                  .default +
                ")",
            }}
          >
          </div>
        </div>


        <div className="category category-absolute">
        <img
          style={{borderRadius:20, zIndex: 3, marginTop:"-30rem", width: 350, backgroundColor:'rgba(0,0,0,0.5)'}}
          alt="..."
          src={require("assets/logo/GrantOReilly-2.png").default}
        ></img>
        {/*<h1 className="h3-description rellax-text" data-rellax-speed="-1" >
          Grant Blaise O'Reilly's
        </h1>

          <h1 className="h1-seo" data-rellax-speed="-1">
          Art
          </h1>*/}

        </div>
        <div className="category category-absolute rellax-text"
        data-rellax-speed="1">

        <Col className="text-center ml-auto mr-auto" md="8"  style={{marginTop:-20}}>
          <Button
            className="btn-round btn-icon mr-1"
            color="facebook"
            onClick={routeChangeFaceBook}
          >
            <FontAwesomeIcon icon="fab fa-facebook-square"/>
          </Button>
          <Button
            className="btn-icon btn-round mr-1"
            style={{marginLeft:10}}
            color="instagram"
            onClick={routeChangeInstagram}
          >
            <FontAwesomeIcon icon="fa-brands fa-instagram"/>
          </Button>
          <Button
            className="btn-icon btn-round mr-1"
            style={{marginLeft:10}}
            color="instagram"
            onClick={routeChangeTikTok}
          >
            <FontAwesomeIcon icon="fa-brands fa-tiktok"/>
          </Button>
          <h6>
            Designed by{" "}
            <a href="https://ausphere.com" target="_blank">
              <img
                alt="..."
                className="invision-logo"
                src={require("assets/logo/ausphereLogo.png").default}
              ></img>
            </a>
          </h6>
        </Col>
        </div>
      </div>
    </>
  );
}

export default HomePresentationHeader;
