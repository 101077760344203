import React, { useContext, useEffect, useState } from "react";
import "./CartList.css";
//import { ProductContext } from "../../Services/Product/ProductContext";
import { CartContext } from "../../Services/Cart/CartContext";
import CartItem from "./CartItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Col, Row, Container
} from "reactstrap";
import { CLIENT_ID, CURRENCY } from './Config'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
//import { useProductsDispatch } from '../GBOAProducts/GBOAProductsContext.js';
import { useHistory } from "react-router-dom";
import Payment from "../Stripe/Payment.js";

const CartList = ({ closeCart, show }) => {
  const history = useHistory();
  //const prodDispatch = useProductsDispatch();

  const [showPayPal, setShowPayPal] = useState(false);
  const [showStripe, setShowStripe] = useState(false);

  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);

  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const { cartItem, dispatch } = useContext(CartContext);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalCartItems, setTotalCartItems] = useState(0);

  const calculateTotal = () => {
    let totalAmount = 0;
    let totalCart = 0;
    cartItem.map((item) => {
      totalAmount = totalAmount + item.count * parseFloat(item.priceValue);
      totalCart = totalCart + item.count;
    });
    setTotalPrice(totalAmount);
    setTotalCartItems(totalCart);
  };

  useEffect(() => {
    calculateTotal();
  }, [cartItem]);


  const checkout = ()=>{
      //alert("Checkout: Subtotal ₹"+totalPrice);
      setShowPayPal(true);
      //closeCart();
  }

  const toggle = () => {
    setShowStripe(!showStripe);
  }

  const stripeCheckout = ()=>{
      setShowStripe(!showStripe);
  }

  const getItems = () =>{
    //console.log(cartItem);
    //setPurchasedItems([]);
    let purchasedItems = [];
    console.log(cartItem);
    cartItem.map((items) => (

      purchasedItems.push(
        {
            name: items.name,
            description: "",
            unit_amount: {
                currency_code: "AUD",
                value: items.priceValue
            },
            quantity: 1
        })
      ));
      console.log(purchasedItems);
      return purchasedItems;
  }

  // const removeFromCart = () => {
  //   cartItem.map((item) => (
  //     dispatch({
  //       type: REMOVE_CART_ITEM,
  //       payload: item,
  //              })
  //   ));
  // };

  // const implementUpdate = (index) => {
  //   const updatedProd = products.map((product,i) => {
  //     if (i === index) {
  //       return {...product, sold: true};
  //     } else {
  //       return product;
  //     }
  //   });
  // }

  // const updateItems = () =>{
  //   cartItem.map((item) => {
  //     prodDispatch({
  //       type: 'changed',
  //       products: {
  //         ...item,
  //         sold: true
  //       }
  //     })
  //   });
  //
  //   prodDispatch({type: 'save'});
  // }


  // creates a paypal order
  const createOrder = (data, actions) => {

      return actions.order.create({
          purchase_units: [
              {
                  description: "Purchase for Grant Blaise O'Reilly's Art",
                  amount: {
                      currency_code: "AUD",
                      value: totalPrice,
                      breakdown: {
                          item_total: {
                              currency_code: "AUD",
                              value: totalPrice,
                          }
                      }
                  },
                  items: getItems()
              },
          ],
      }).then((orderID) => {
              setOrderID(orderID);
              return orderID;
          });
  };

  // check Approval
  const onApprove = (data, actions) => {

      return actions.order.capture().then(function (details) {
          console.log(details);

          const { payer } = details;
          //alert("Thank you for payment! Order ID:"+ details.id);
          //updateItems();
          setShowPayPal(false);
          setSuccess(true);
          //removeFromCart();
          closeCart();
          history.push("/completion");
      });
  };

  const onCancel = (data, actions) => {
    alert("Payment cancelled!");
    setShowPayPal(false);
  };

  const onError = (err) => {
    console.log(err);
    alert("Error:"+ err.error);
    setShowPayPal(false);
  };

  const displayPaymentOptions = () => {
    return(
      <div>
      <PayPalButtons
          style={{ layout: "vertical" }}
          createOrder={createOrder}
          onApprove={onApprove}
          onCancel={onCancel}
          onError={onError}
      />

      <Button
               style={{marginLeft:0, marginTop:0, width: "100%"}}
               color="info"
               size="lg"
               onClick={() => stripeCheckout()}>
               <img
                 alt="..."
                 className="img img-raised rounded"
                 style={{width:80, height:30}}
                 src={require("assets/img/Stripe.png").default}
               ></img>
      </Button>
      </div>
    );
  }

  const displayStripeOptions = () => {
    return(
      <Container>
      <Col>
        <div style={{display:'flex', flexDirection: 'row', justifyContent:'flex-end'}}>
        <Button className="btn-icon btn-round"
                style={{marginLeft:240, marginTop:0}}
                color="info"
                onClick={() => toggle()}
                outline>
                <FontAwesomeIcon icon="fa-solid fa-xmark" />
        </Button>
        </div>
        <Payment total = {totalPrice}
                 currency = {"AUD"} />
      </Col>
      </Container>
      );

  }

  return (
    <PayPalScriptProvider options={{ "client-id": CLIENT_ID, "currency": CURRENCY }}>
    <div className={showHideClassName}>
      <div style={{display:'flex', flexDirection: 'row', justifyContent:'flex-start'}}>
        <p style={{fontWeight:'bold', fontSize: 25}}>Cart</p>
        <Button className="btn-icon btn-round"
                style={{marginLeft:240, marginTop:0}}
                color="info"
                onClick={() => closeCart()}
                outline>
                <FontAwesomeIcon icon="fa-solid fa-xmark" />
        </Button>
      </div>
      <div style={{marginBottom:10}}>
        Number of Items: <span className="cart-count">{totalCartItems}</span>
      </div>
      <div className="cart-container">
        {cartItem.length > 0 ? (
          <div>
            {cartItem.map((items) => (
              <CartItem
                cartObj={items}
                key={items.id}
              />
            ))}
          </div>
        ) : (
          <p className="p-10 text-red-500 decoration-clone font-semibold">
            Your cart is empty
          </p>
        )}
      </div>
      <div style={{borderRadius:20, background: 'lightblue', padding:'10px',marginTop:10,marginBottom:10}}>
        <div>
          <span style={{fontWeight:'bold', fontSize: 25}}>Total :</span>
          <span style={{fontWeight:'bold', fontSize: 25, color: 'darkorange', marginLeft:10}}>A${totalPrice}</span>
        </div>
        <Button
                 style={{marginLeft:0, marginTop:0}}
                 color="info"
                 size="lg"
                 onClick={()=> checkout()}>Checkout</Button>
       {/*<Button
                style={{marginLeft:0, marginTop:0}}
                color="info"
                size="lg"
                onClick={()=> updateItems()}>Test</Button> */}
      </div>
      {showPayPal ? (displayPaymentOptions()) : null }
      {showStripe ? (displayStripeOptions()) : null }

    </div>
    </PayPalScriptProvider>
  );
};

export default CartList;
