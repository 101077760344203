import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import Rellax from "rellax";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import GBOAScrollTransparentHeader from "components/Navbars/GBOAScrollTransparentHeader.js";
import GBOANewsHeader from "components/Headers/GBOANewsHeader.js";
import GBOAFooterSocial from "components/Footers/GBOAFooterSocial.js";
import StudioBasicComponents from "../presentation-sections/StudioBasicComponents.js";
import GBOAUpComing from "../presentation-sections/GBOAUpComing.js";
import ReactPlayer from 'react-player';
import myVideo from '../../assets/video/Ukukhanya-reel.mp4'
import gahanaVideo1 from '../../assets/video/Gahana.mp4'
import gahanaVideo2 from '../../assets/video/Gahana-2.mp4'


function GBOANews() {

  const [specialitySelect, setSpecialitySelect] = React.useState(null);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    return function cleanup() {
      document.body.classList.remove("presentation-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ScrollTransparentNavbar />
      {/*GBOAScrollTransparentHeader(true)*/}
      <GBOANewsHeader />
      <GBOAUpComing />

      <div className="wrapper">
        <StudioBasicComponents viewButton={'false'} />
          <div className="about-office">
          <Container>
            <Row className="text-center">
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Gahana, Jewel of India</h2>
                <h4 className="description">
                  Gahana, Jewel of India is the latest release, original acrylic painting 90cmx60cm of a tiger.
                </h4>
              </Col>
            </Row>
            <Row>
            <Col md="4"></Col>
            <Col md="12" className="text-center">
              <img
                alt="..."
                style={{borderRadius: 30, width:"70%"}}
                src={require("assets/art/Gahana.jpg").default}
              ></img>
            </Col>
            </Row>
            <Row>
              <Col md="3"></Col>
              <Col md="3" style={{align:'center'}}>
                <ReactPlayer
                  url={gahanaVideo1}
                  width='100%'
                  height='100%'
                  controls = {true}
                />
              </Col>
              <Col md="3" style={{align:'center'}}>
                <ReactPlayer
                  url={gahanaVideo2}
                  width='100%'
                  height='100%'
                  controls = {true}
                />
              </Col>
            </Row>
          </Container>
            <Container>
              <Row className="text-center">
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="title">Ukukhanya</h2>
                  <h4 className="description">
                    Ukukhanya is the latest release, original oil painting 94.1cmx94.1cm of a lion.
                  </h4>
                </Col>
              </Row>
              <Row>
              <Col md="4"></Col>
              <Col md="12" className="text-center">
                <img
                  alt="..."
                  style={{borderRadius: 30, width:"70%"}}
                  src={require("assets/art/Ukukhanya.jpg").default}
                ></img>
              </Col>
              </Row>
              <Row>
                <Col md="4"></Col>
                <Col md="4" style={{align:'center'}}>
                  <ReactPlayer
                    url={myVideo}
                    width='100%'
                    height='100%'
                    controls = {true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <img
                    alt="..."
                    style={{borderRadius: 30, marginTop: 30}}
                    src={require("assets/art/news/Ukukhanya/Ukukhanya-04.jpg").default}
                  ></img>
                </Col>
                <Col md="3">
                  <img
                    alt="..."
                    style={{borderRadius: 30, marginTop: 30}}
                    src={require("assets/art/news/Ukukhanya/Ukukhanya-05.jpg").default}
                  ></img>
                </Col>
                <Col md="3">
                  <img
                    alt="..."
                    style={{borderRadius: 30, marginTop: 30}}
                    src={require("assets/art/news/Ukukhanya/Ukukhanya-06.jpg").default}
                  ></img>
                </Col>
                <Col md="3">
                  <img
                    alt="..."
                    style={{borderRadius: 30, marginTop: 30}}
                    src={require("assets/art/news/Ukukhanya/Ukukhanya-07.jpg").default}
                  ></img>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="text-center">
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="title">Scar</h2>
                  <h4 className="description">
                    Scar the leopard is a current release, original oil painting 76cmx60cm of a leopard.
                  </h4>
                </Col>
              </Row>
              <Row>
              <Col md="4"></Col>
              <Col md="4">
                <img
                  alt="..."
                  style={{borderRadius: 30}}
                  src={require("assets/art/ScarTheLeopard.jpg").default}
                ></img>
              </Col>
              </Row>
              <Row>
                <Col md="4">
                  <img
                    alt="..."
                    style={{borderRadius: 30, marginTop: 30}}
                    src={require("assets/art/news/Scar/Scar-07.jpg").default}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    style={{borderRadius: 30, marginTop: 30}}
                    src={require("assets/art/news/Scar/Scar-06.jpg").default}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    style={{borderRadius: 30, marginTop: 30}}
                    src={require("assets/art/news/Scar/Scar-05.jpg").default}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    style={{borderRadius: 30, marginTop: 30}}
                    src={require("assets/art/news/Scar/Scar-03.jpg").default}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    style={{borderRadius: 30, marginTop: 30}}
                    src={require("assets/art/news/Scar/Scar-02.jpg").default}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    style={{borderRadius: 30, marginTop: 30}}
                    src={require("assets/art/news/Scar/Scar-01.jpg").default}
                  ></img>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="text-center">
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="title">Dew Drop</h2>
                  <h4 className="description">
                    Dew Drop is a current release, original oil painting 60cmx50cm of a tiger staring out of a bamboo bush.
                  </h4>
                </Col>
              </Row>
              <Row>
              <Col md="4"></Col>
              <Col md="4">
                <img
                  alt="..."
                  className="rounded img-raised"
                  src={require("assets/art/DueDrop.jpg").default}
                ></img>
              </Col>
              </Row>
              <Row>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/art/news/tiger-06.jpg").default}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/art/news/tiger-05.jpg").default}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/art/news/tiger-04.jpg").default}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/art/news/tiger-03.jpg").default}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/art/news/tiger-02.jpg").default}
                  ></img>
                </Col>
                <Col md="4">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={require("assets/art/news/tiger-01.jpg").default}
                  ></img>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="separator-line bg-info"></div>

        <div style ={{backgroundColor: '#FFFFF',}}>
           <GBOAFooterSocial />
        </div>
      </div>
    </>
  );
}

export default GBOANews;
