import React from "react";

// reactstrap components
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Container,Row, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SocialSiteButtons from "./SocialSiteButtons.js";
// core components

function GBOAAboutUsHeader() {
  let pageHeader = React.createRef();
  // React.useEffect(() => {
  //   if (window.innerWidth > 991) {
  //     const updateScroll = () => {
  //       let windowScrollTop = window.pageYOffset / 3;
  //       pageHeader.current.style.transform =
  //         "translate3d(0," + windowScrollTop + "px,0)";
  //     };
  //     window.addEventListener("scroll", updateScroll);
  //     return function cleanup() {
  //       window.removeEventListener("scroll", updateScroll);
  //     };
  //   }
  // });

  const history = useHistory();

  const routeChangeFaceBook = () =>{
    let path = "https://www.facebook.com/grantblaiseoreillyart";
    window.open(path, '_blank');
  }

  const routeChangeTikTok = () =>{
    let path = "https://www.tiktok.com/@grantblaise";
    window.open(path, '_blank');
  }

  const routeChangeInstagram = () =>{
    let path = "https://www.instagram.com/grantblaiseart/";
    window.open(path, '_blank');
  }

  return (
    <>
    <div className="page-header page-header-mini">
     <div className="rellax-header" data-rellax-speed="-4">
      <div
        className="page-header-image"
        style={{
          backgroundColor: 'rgba(0,0,0,0.6)',
          backgroundBlendMode: 'multiply',
          backgroundImage:
            "url(" + require("assets/img/bg21.jpg").default + ")",
        }}
        ref={pageHeader}
      ></div>
      <div className="content-center">
        <Container>
          <Col>
          <h1 className="title">About Us</h1>
          <Row md="6">
            <Col>
              <h4 className="info-title"><FontAwesomeIcon icon="fa-solid fa-location-dot" style={{marginRight:10}}/>
              Find us at the office</h4>

              <p className="description">
                We currently located on the Sunshine Coast,
                Australia <br></br>
              </p>
            </Col>
            <Col>
              <h4 className="info-title"><FontAwesomeIcon icon="fa-solid fa-phone" style={{marginRight:10}}/>
              Give us a ring</h4>
              <p className="description">
                Grant Blaise O'Reilly <br></br>
                +61 426 892 715 <br></br>
                Mon - Fri, 8:00-22:00 (AEST Brisbane)
              </p>
            </Col>
          </Row>
          <Row>
              <SocialSiteButtons />
            </Row>
         </Col>
        </Container>
      </div>
      </div>
    </div>
    </>
  );
}

export default GBOAAboutUsHeader;
