/*
================================================================================
Grant Blaise O'Reilly's Art Component
Ausphere product 2024-05-03
Developed by Grant O'Reilly
================================================================================
*/
import React, {useState, useEffect, useContext} from "react";

import {
  Row,
  Col
} from "reactstrap";

import GBOAPrintItem from "./GBOAPrintItem.js"

function GBOAPrintingDescItem(printItem, images){

  return (
  <div style={{marginBottom: 10, border: '1px',borderStyle: 'solid',  borderColor:'#2CA8FF', color: '#2CA8FF',padding: '10px', fontSize: '1.1em' }}>
  <h5><b>{printItem.medium}</b></h5>
  <hr></hr>
    {printItem.item.map(print_item => (
      GBOAPrintItem(print_item, images)
    ))}
  </div>
 )

}

export default GBOAPrintingDescItem;
