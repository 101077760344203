import { useState,useEffect, useContext } from 'react';
import { useProducts, useProductsDispatch } from './GBOAProductsContext.js';
import GBOAPainting from "../../../../views/examples/GBOAPaintingsCarousel2.js";
import Axios from "axios";
export default function GBOAProductsList() {

  const products = useProducts();

  return (

    <div>
      {products.map(product_item => (
        GBOAPainting(product_item)
      ))}
    </div>
  );
}
