/*
================================================================================
Grant Blaise O'Reilly's Art Component
Ausphere product 2024-05-03
Developed by Grant O'Reilly
================================================================================
*/
import React, {useState, useEffect, useContext} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardLink,
  CardTitle,
  Collapse,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

import {CartContext } from "../../components/ShoppingCart/Services/Cart/CartContext";
import {
  ADD_CART_ITEM,
  REMOVE_CART_ITEM,
  UPDATE_CART_ITEM_COUNT,
} from "../../components/ShoppingCart/Services/Cart/action-types";

function GBOAPrintItem(printItem, images){

  const { cartItem, dispatch } = useContext(CartContext);
  const [addCart, setAddCart] = React.useState(false);

  const priceRequest = (price) => {
        return (
               <h5 className="main-price" style={{color: 'orange', fontWeight: 'bold'}}>
                <img
                  alt="..."
                  style={{width:"32px", marginRight: 10}}
                  src={require("assets/logo/australia.png").default}
                ></img>
                {price}
                </h5>
              )
  }

  const addToCart = (item) => {
    setAddCart(true);
    item.isAddedtoCart = true;
    item.count = 1;
    dispatch({
      type: ADD_CART_ITEM,
      payload: item,
    });
  };

  const removeFromCart = (item) => {
    setAddCart(false);
    item.isAddedtoCart = false;
    item.count = 0;
    dispatch({
      type: REMOVE_CART_ITEM,
      payload: item,
    });
  };

  const isInCart = (item) => {
     let found = false;
     cartItem.map((prod) => {
       if (item.id === prod.id){
          found = true;
        }
      }
     );
     return found;
  }

  const cartButton = (price, priceValue) =>{

        let val = isInCart(printItem);

        if (val){
            return(
               <Row  style={{marginRight: 10}}>
                <Button
                        style={{padding:'10px', width:180}}
                        color="primary"
                        to={{pathname: "/painting-contact-us",
                             state: { image : images[1].filename,
                                      price : price,
                                      name  : printItem.name}
                           }}
                        onClick={() => removeFromCart({"id": printItem.id, "name":printItem.name, "price":printItem.price, "priceValue":printItem.priceValue, "smallImage": require("assets/art/"+images[1].filename).default })}>
                        Remove Cart
                        <FontAwesomeIcon icon="fa-solid fa-trash-can" style={{marginLeft:10, fontSize: '20'}}/>
                </Button>
               </Row>
            )
        }
        else{

                if (addCart){
                  return(
                     <Row  style={{marginRight: 10}}>
                      <Button
                              style={{padding:'10px', width:180}}
                              color="primary"
                              to={{pathname: "/painting-contact-us",
                                   state: { image : images[1].filename,
                                            price : printItem.price,
                                            name  : printItem.name}
                                 }}
                              onClick={() => removeFromCart({"id": printItem.id, "name":printItem.name, "price":price, "priceValue":printItem.priceValue, "smallImage": require("assets/art/"+images[1].filename).default })}>
                              Remove Cart
                              <FontAwesomeIcon icon="fa-solid fa-trash-can" style={{marginLeft:10, fontSize: '20'}}/>
                      </Button>
                     </Row>
                  )
                }
                else{
                  return(
                     <Row style={{marginRight: 10}}>
                      <Button
                              style={{padding:'10px', width:180}}
                              color="success"
                              to={{pathname: "/painting-contact-us",
                                   state: { image : require("assets/art/"+images[1].filename).default ,
                                            price : price,
                                            name  : printItem.name}
                                 }}
                              onClick={() => addToCart(printItem)}>
                              Add to Cart
                              <FontAwesomeIcon icon="fa-solid fa-cart-plus" style={{marginLeft:10, fontSize: '20'}}/>
                      </Button>
                     </Row>
                  )
                }
        }
  }


  return (
  <div>
   <Row>
   <Col className="ml-auto mr-auto" md="4">
       {printItem.size} <br></br>{printItem.description}
   </Col>
    <Col className="ml-auto mr-auto" md="4">
        {priceRequest(printItem.price)}
    </Col>
    <Col className="ml-auto mr-auto" md="4">
        {cartButton(printItem.price, printItem.priceValue)}
    </Col>
  </Row>
  </div>
 )

}

export default GBOAPrintItem;
