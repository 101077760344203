import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class GBOAImageComponent extends React.Component {
  state = { isOpen: false };

  handleShowDialog = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <div>
        <img
          style={{marginTop: this.props.marginTop, width:this.props.imageSize, borderRadius: 20}}
          alt="no image"
          src={
            this.props.image
          }
          onClick={this.handleShowDialog}
        ></img>

        <FontAwesomeIcon icon="fa-solid fa-maximize" style={{marginLeft:-30, backgroundColor: 'ivory', borderRadius: 20, padding: 5}} onClick={this.handleShowDialog}/>
        {this.state.isOpen && (
          <dialog
            style={{ position: "absolute",
                     left: this.props.left,
                     top: "10%",
                     width: "200%",
                     zIndex:1 }}
            open
            onClick={this.handleShowDialog}
          >
            <img
              style={{ width: "200%", borderRadius: 20}}
              alt="no image"
              src={
                this.props.image
              }
              onClick={this.handleShowDialog}
            />
          </dialog>
        )}
      </div>
    );
  }
}
