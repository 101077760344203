import React, { useReducer, useState, useContext }  from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CartList from "../ShoppingCart/Components/Cart/CartList";
import {CartContext} from "../ShoppingCart/Services/Cart/CartContext";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavLink,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";


function ScrollTransparentNavbar(props) {

  const lower = 99; //199;  //499
  const upper = 100; //200;  //500

  const [cartModal, setCartModal] = useState({show:false});
  const { cartItem, dispatch } = useContext(CartContext);

  const openCart = () => {
    setCartModal({show:true});
  };

 const closeCarts = () => {
    setCartModal({show:false});
  };

  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [navbarColor, setNavbarColor] = React.useState(
    (document.documentElement.scrollTop > lower || document.body.scrollTop) > lower
      ? ""
      : " navbar-transparent"
  );
  const [buyButtonColor, setBuyButtonColor] = React.useState(
    (document.documentElement.scrollTop > lower || document.body.scrollTop) > lower
      ? "info"
      : "neutral"
  );

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > lower ||
        document.body.scrollTop > lower
      ) {
        setNavbarColor("");
        setBuyButtonColor("info");
      } else if (
        document.documentElement.scrollTop < upper ||
        document.body.scrollTop < upper
      ) {
        setNavbarColor(" navbar-transparent");
        setBuyButtonColor("neutral");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const displayCart = () =>{
    if (props.cartVisible === true){
    return(
      <NavItem>
        <NavLink>
        <Button
          outline
          style={{height:35}}
          className="nav-link btn-default"
          color="info"
          href="#"
          onClick={openCart}
        >
          <Container>
            <Row>
              <a href="#" onClick={openCart}>
                 <FontAwesomeIcon icon="fa-solid fa-cart-plus" style={{color: 'lightgray',fontSize:20}} />
              </a>
              <div style={{borderRadius:15, width:23, height:23,textAlign: 'center', backgroundColor: 'darkorange', marginTop:-15, marginLeft:-0}}>
                <p style={{color: 'white',fontWeight:'bold',fontSize:10,marginTop:3}}>{cartItem.length}</p>
              </div>
            </Row>
          </Container>
        </Button>
        </NavLink>
      </NavItem>
    )
    }
    else return "";
  }

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top" + navbarColor} color="white" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <img
                alt="..."
                style={{width:'120px'}}
                src={require("assets/logo/GrantOReilly-2.png").default}
              ></img>
            </NavbarBrand>
            <UncontrolledTooltip target="navbar-brand">
              Grant Blaise O'Reilly's signature the trade mark for all Grant Blaise O'Reilly's original paintings.
            </UncontrolledTooltip>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>

              <NavItem>
                <NavLink to="/" tag={Link} active><FontAwesomeIcon icon="fa-solid fa-house" style={{fontSize: '20', marginRight:5}}/>HOME</NavLink>
              </NavItem>
              {/*
              <NavItem>
                <NavLink to="/gboa-product-page" tag={Link} active><FontAwesomeIcon icon="fa-solid fa-cart-shopping" style={{fontSize: '20', marginRight:5}}/>SALES</NavLink>
              </NavItem>
              */}
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                <FontAwesomeIcon icon="fa-solid fa-cart-shopping" style={{fontSize: '20', marginRight:5}}/>
                  <p>SALES</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                  <DropdownItem to="/gboa-product-page" tag={Link}>
                    <FontAwesomeIcon icon="fa-solid fa-palette" style={{fontSize: '20', marginRight:5}}/>
                    ORIGINAL ARTWORK
                  </DropdownItem>
                  <DropdownItem to="/printing" tag={Link}>
                    <FontAwesomeIcon icon="fa-solid fa-print" style={{fontSize: '20', marginRight:5}}/>
                    PRINTS
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem>
                <NavLink to="/about-us" tag={Link} active><FontAwesomeIcon icon="fa-solid fa-user-gear" style={{fontSize: '20', marginRight:5}}/>ABOUT</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/contact-us" tag={Link} active><FontAwesomeIcon icon="fa-solid fa-phone-volume" style={{fontSize: '20', marginRight:5}}/>CONTACT</NavLink>
              </NavItem>


              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                <FontAwesomeIcon icon="fa-solid fa-paintbrush" style={{fontSize: '20', marginRight:5}}/>
                  <p>STUDIO</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                  <DropdownItem to="/news" tag={Link}>
                    <FontAwesomeIcon icon="fa-solid fa-screwdriver-wrench" style={{fontSize: '20', marginRight:5}}/>
                    PROCESS
                  </DropdownItem>
                  <DropdownItem to="/portfolio" tag={Link}>
                    <FontAwesomeIcon icon="fa-regular fa-image" style={{fontSize: '20', marginRight:5}}/>
                    PORTFOLIO
                  </DropdownItem>
                  <DropdownItem to="/showroom" tag={Link}>
                    <FontAwesomeIcon icon="fa-solid fa-mountain-sun" style={{fontSize: '20', marginRight:5}}/>
                    SHOW ROOM
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              {displayCart()}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <CartList closeCart={closeCarts} show={cartModal.show} />
    </>
  );
}

export default ScrollTransparentNavbar;
