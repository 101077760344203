import React from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from "reactstrap";

// core components

function GBOAFooterSocial() {

  const history = useHistory();

  const routeChangeTikTok = () =>{
    let path = "https://www.tiktok.com/@grantblaise";
    window.open(path, '_blank');
  }

  const routeChange = () =>{
    let path = "https://www.facebook.com/grantblaiseoreillyart";
    window.open(path, '_blank');
  }

  const routeChangeInstagram = () =>{
    let path = "https://www.instagram.com/grantblaiseart/";
    window.open(path, '_blank');
  }

  const routeChangeGBO = () =>{
    let path = "https://www.facebook.com/grantblaise.oreilly";
    window.open(path, '_blank');
  }

  const routeChangeLinkedin = () =>{
    let path = "https://www.linkedin.com/in/grantboreilly/";
    window.open(path, '_blank');
  }

  const routeChangeGoogle = () =>{
    let path = "https://scholar.google.com/citations?user=3K0mLSIAAAAJ&hl=en&oi=ao";
    window.open(path, '_blank');
  }

  return (
    <>
      <footer className="footer">
        <Container>
              <Col md="12">
                 <Row>
                 <Col><Link to="/"> HOME </Link></Col>
                 <Col><Link to="/gboa-product-page"> SALES </Link></Col>
                 <Col><Link to="/news">STUDIO </Link></Col>
                 <Col><Link to="/portfolio"> PORTFOLIO </Link></Col>
                 <Col><Link to="/about-us">ABOUT </Link></Col>
                 <Col><Link to="/contact-us"> CONTACT </Link></Col>
                 </Row>
              </Col>
              <hr></hr>
              <Col md="12">
                  <h3>Grant Blaise O'Reilly's Art</h3>
                <p>
                  A virtual gallery of all the art work created by Grant Blaise O'Reilly.
                </p>
              </Col>
              <Col md="12">
              <Button
                className="btn-round btn-icon mr-1"
                color="facebook"
                size="lg"
                onClick={routeChange}
              >
                <FontAwesomeIcon icon="fab fa-facebook-square" style={{fontSize:30}}/>
              </Button>
              <Button
                className="btn-icon btn-round mr-1"
                style={{marginLeft:10}}
                color="instagram"
                size="lg"
                onClick={routeChangeInstagram}
              >
                <FontAwesomeIcon icon="fa-brands fa-instagram" style={{fontSize:30}}/>
              </Button>
              <Button
                className="btn-icon btn-round mr-1"
                style={{marginLeft:10}}
                color="instagram"
                size="lg"
                onClick={routeChangeTikTok}
              >
                <FontAwesomeIcon icon="fa-brands fa-tiktok" style={{fontSize:30}}/>
              </Button>
              </Col>
          <hr></hr>
          <ul className="social-buttons">
          <Container>
          <Col className="mr-1" md="12">
            <Card className="card-profile card-plain">
              <div className="card-avatar">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <img
                    alt="..."
                    className="img img-raised"
                    src={require("assets/logo/GBO-02.jpg").default}
                  ></img>
                </a>
              </div>
              <CardBody>
                <CardTitle tag="h3">Grant Blaise O'Reilly</CardTitle>
                <h6 className="category text-info">Artist and Scientist</h6>
                <p className="card-description">
                  A love for realism painting and sketching.
                </p>
                <CardFooter>
                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    onClick={routeChangeLinkedin}
                  >
                    <FontAwesomeIcon icon="fab fa-linkedin"/>
                  </Button>
                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    onClick={routeChangeGBO}
                  >
                    <FontAwesomeIcon icon="fab fa-facebook-square"/>
                  </Button>
                  <Button
                    className="btn-icon btn-round"
                    color="info"
                    onClick={routeChangeGoogle}
                  >
                    <FontAwesomeIcon icon="fab fa-google"/>
                  </Button>
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
          </Container>
          </ul>
          <div className="copyright pull-center" id="copyright">
            Copyright © {new Date().getFullYear()} Ausphere All Rights
            Reserved.
          </div>
        </Container>
      </footer>
    </>
  );
}

export default GBOAFooterSocial;
